import { IconCacheService } from '@finxone-platform/shared/services';

export function iconNameToSvg(
  iconName: string,
  width: string | undefined,
  height: string | undefined,
  iconCacheService: IconCacheService,
): string {
  const svgText = iconCacheService.getIcon(iconName);
  if (!svgText) {
    throw new Error(`Icon "${iconName}" not found`);
  }

  // Parse the SVG string into a DOM element
  const parser = new DOMParser();
  const doc = parser.parseFromString(svgText, 'image/svg+xml');
  const svgElement = doc.querySelector('svg');

  if (!svgElement) {
    throw new Error('Invalid SVG content');
  }

  // Set width and height attributes if provided
  if (width) {
    svgElement.setAttribute('width', width);
  }
  if (height) {
    svgElement.setAttribute('height', height);
  }

  // Serialize the modified SVG back to a string
  const serializer = new XMLSerializer();
  return serializer.serializeToString(svgElement);
}
