<div [ngStyle]="contentStyle" class="section password-forgot pointer-events-none">
  <p *ngIf="dataIn?.attri?.widgetProperties?.subHeadingLabel" [ngStyle]="mergedHeadingStyle">
    {{ dataIn?.attri?.widgetProperties?.subHeadingLabel }}
  </p>
  <div class="text-left mt-3">
    <form class="beneficiary-form" [formGroup]="passwordForm">
      <div>
        <finxone-input
          [type]="'password'"
          [name]="'newPassword'"
          [label]="dataIn?.attri?.widgetProperties['passFieldLabel1']"
          formControlName="newPassword"
          [formControlInvalid]="
            !!(
              passwordForm.controls['newPassword']?.dirty &&
              passwordForm.controls['newPassword']?.invalid &&
              passwordForm.controls['newPassword']?.touched
            )
          "
          [value]="passwordForm.controls['newPassword']?.value ?? ''"
          [validationConfig]="[
            {
              type: 'required',
              message: 'Field is mandatory'
            }
          ]"
        >
        </finxone-input>
        <div class="mt-3">
          <finxone-input
            [type]="'password'"
            [name]="'confirmPassword'"
            [label]="dataIn?.attri?.widgetProperties['passFieldLabel2']"
            formControlName="confirmPassword"
            [formControlInvalid]="
              !!(
                passwordForm.controls['confirmPassword']?.dirty &&
                passwordForm.controls['confirmPassword']?.invalid &&
                passwordForm.controls['confirmPassword']?.touched
              )
            "
            [value]="passwordForm.controls['confirmPassword']?.value ?? ''"
            [validationConfig]="[
              {
                type: 'required',
                message: 'Field is mandatory'
              }
            ]"
          >
          </finxone-input>
        </div>
        <div class="d-flex">
          <button
            class="nested-button"
            [ngClass]="[
              pageConfig?.isUsingHtmlStructure
                ? 'w-100'
                : !isAllowedGlobalStyling
                ? 'position-absolute-fill-left-right'
                : '',
              isAllowedGlobalStyling ? globalStylingClass : ''
            ]"
            (click)="enableSubmit = true; onSubmit()"
            [ngStyle]="!isAllowedGlobalStyling ? reviewDetailsButtonStyle : {}"
            type="submit"
            [innerHTML]="buttonContent"
          ></button>
        </div>
      </div>
    </form>
  </div>
</div>
