import { Router } from '@angular/router';
import { APP_ZONES } from '@finxone-platform/shared/sys-config-types';
import { Store } from '@ngxs/store';
import { take, tap } from 'rxjs';
import { SetCardNameAction } from '../../../actions/card-management.action';
import { WorkflowFormsService } from '../../../services/workflow-forms/workflow-forms.service';
import { CardState } from '../../../state/card.state';
import { formatNameAsUrl } from '../../zone-url.utils';

export function onSetCardName(
  store: Store,
  router: Router,
  workflowFormsService: WorkflowFormsService,
): void {
  const cardId = store.selectSnapshot(CardState.getCurrentCardId);
  const data = workflowFormsService.getFormValues('cards.cardName');
  const isFormValid = workflowFormsService.isFormValid('cards.cardName');
  if (!isFormValid) {
    return;
  }
  handleCardNameForm(data, cardId, store, router);
}

function handleCardNameForm(data: any, cardId: string | null, store: Store, router: Router): void {
  const cardName = data.cardName;
  dispatchSetCardName(store, cardId || '', cardName, router);
}

function dispatchSetCardName(store: Store, cardId: string, cardName: string, router: Router): void {
  store
    .dispatch(new SetCardNameAction(cardId, cardName))
    .pipe(
      take(1),
      tap({
        next: () => navigateToLandingPage(router),
        error: (error) => {
          console.error('Error dispatching SetCardNameAction:', error);
        },
      }),
    )
    .subscribe();
}

function navigateToLandingPage(router: Router): void {
  const targetUrl = `/zones/${formatNameAsUrl(APP_ZONES.CARD_MANAGEMENT_ZONE)}/landing-page`;
  router.navigateByUrl(targetUrl);
}
