import { WidgetNames } from './config.type';
import { ComponentLabel } from './workflow.type';
import { NewWorkflowWidgetConfig } from './workflow.v2.type';
export type WidgetPath = (string | number)[];

export class NotLayoutWidgetError extends Error {}

export function isLayoutWidget(label: ComponentLabel) {
  return layoutWidgetsArray.includes(label);
}

export const layoutWidgetsArray: ComponentLabel[] = [
  WidgetNames.SECTION,
  WidgetNames.TWO_COLUMN,
  WidgetNames.TAB,
];

export function findWidgetPath(
  uniqueId: string | number,
  widgets: NewWorkflowWidgetConfig[],
  currentPath: WidgetPath = [],
): WidgetPath | null {
  for (const widget of widgets) {
    const newPath = [...currentPath, widget.uniqueId];

    if (widget.uniqueId.toString() === uniqueId.toString()) {
      return newPath;
    }

    if (widget.childComponents) {
      if (Array.isArray(widget.childComponents[0])) {
        // Handle nested arrays of childComponents
        for (const childArray of widget.childComponents as NewWorkflowWidgetConfig[][]) {
          const childPath = findWidgetPath(uniqueId, childArray, newPath);
          if (childPath) return childPath;
        }
      } else {
        // Handle single array of childComponents
        const childPath = findWidgetPath(
          uniqueId,
          widget.childComponents as NewWorkflowWidgetConfig[],
          newPath,
        );
        if (childPath) return childPath;
      }
    }
  }

  return null;
}

export function deleteWidget(uniqueId: string | number, widgets: NewWorkflowWidgetConfig[]): void {
  const path = findWidgetPath(uniqueId, widgets);
  if (!path) return; // Widget not found, do nothing

  const deleteFromArray = (arr: NewWorkflowWidgetConfig[], pathIndex: number): void => {
    if (pathIndex === path.length - 1) {
      const index = arr.findIndex((widget) => widget.uniqueId === path[pathIndex]);
      if (index !== -1) {
        arr.splice(index, 1);
      }
      return;
    }

    const widget = arr.find((w) => w.uniqueId === path[pathIndex]);
    if (widget && widget.childComponents) {
      if (Array.isArray(widget.childComponents[0])) {
        widget.childComponents.forEach((childArray) =>
          deleteFromArray(childArray as NewWorkflowWidgetConfig[], pathIndex + 1),
        );
      } else {
        deleteFromArray(widget.childComponents as NewWorkflowWidgetConfig[], pathIndex + 1);
      }
    }
  };

  deleteFromArray(widgets, 0);
}

export function findWidget(
  uniqueId: string | number,
  widgets: NewWorkflowWidgetConfig[],
): NewWorkflowWidgetConfig | null {
  for (const widget of widgets) {
    if (widget.uniqueId.toString() === uniqueId?.toString()) {
      return widget;
    }

    if (widget.childComponents) {
      let result: NewWorkflowWidgetConfig | null = null;

      if (Array.isArray(widget.childComponents[0])) {
        // Handle 2D array of child components
        for (const childArray of widget.childComponents as NewWorkflowWidgetConfig[][]) {
          result = findWidget(uniqueId, childArray);
          if (result) break;
        }
      } else {
        // Handle 1D array of child components
        result = findWidget(uniqueId, widget.childComponents as NewWorkflowWidgetConfig[]);
      }
      if (result) {
        return result;
      }
    }
  }

  return null;
}

export const TOP_LEVEL_ID = 'TOP_LEVEL';

export function findWidgetAndParent(
  uniqueId: string | number,
  widgets: NewWorkflowWidgetConfig[],
  parent: NewWorkflowWidgetConfig[] | null = null,
): [NewWorkflowWidgetConfig | null, NewWorkflowWidgetConfig[] | null] {
  for (const widget of widgets) {
    if (widget.uniqueId === uniqueId) {
      return [widget, parent || widgets];
    }

    if (widget.childComponents) {
      let result: [NewWorkflowWidgetConfig | null, NewWorkflowWidgetConfig[] | null] = [null, null];

      if (Array.isArray(widget.childComponents[0])) {
        // Handle 2D array of child components
        for (const childArray of widget.childComponents as NewWorkflowWidgetConfig[][]) {
          result = findWidgetAndParent(uniqueId, childArray, childArray);
          if (result[0]) return result;
        }
      } else {
        // Handle 1D array of child components
        result = findWidgetAndParent(
          uniqueId,
          widget.childComponents as NewWorkflowWidgetConfig[],
          widget.childComponents as NewWorkflowWidgetConfig[],
        );
        if (result[0]) return result;
      }
    }
  }

  return [null, null];
}

export function addWidget(
  widget: NewWorkflowWidgetConfig,
  targetParentId: string | number | typeof TOP_LEVEL_ID,
  widgets: NewWorkflowWidgetConfig[],
  targetIndex?: number,
  target2dChildComponentIndex?: number,
): NewWorkflowWidgetConfig[] {
  // If moving to top level
  if (targetParentId === TOP_LEVEL_ID) {
    const widgetIndex = targetIndex ?? widgets.findIndex((v) => v.name === widget.name);
    if (widgetIndex !== undefined && widgetIndex >= 0 && widgetIndex <= widgets.length) {
      widgets.splice(widgetIndex, 0, widget);
    } else {
      widgets.push(widget);
    }
    return widgets;
  }

  // Find the target parent
  const targetParent = findWidget(targetParentId, widgets);

  if (!targetParent) {
    console.error('Target parent not found');
    return widgets;
  }
  if (targetParentId !== TOP_LEVEL_ID) {
    if (!isLayoutWidget((targetParent?.name ?? '') as ComponentLabel)) {
      throw new NotLayoutWidgetError();
    }
  }
  // Ensure childComponents exists
  if (!targetParent.childComponents) {
    targetParent.childComponents = [];
  }

  // Handle 2D array of childComponents
  if (Array.isArray(targetParent.childComponents[0])) {
    const targetArray =
      target2dChildComponentIndex !== undefined &&
      target2dChildComponentIndex < targetParent.childComponents.length
        ? (targetParent.childComponents[target2dChildComponentIndex] as NewWorkflowWidgetConfig[])
        : (targetParent.childComponents[0] as NewWorkflowWidgetConfig[]);

    if (targetIndex !== undefined && targetIndex >= 0 && targetIndex <= targetArray.length) {
      targetArray.splice(targetIndex, 0, widget);
    } else {
      targetArray.push(widget);
    }
  }
  // Handle 1D array of childComponents
  else {
    const widgetIndex = targetIndex ?? targetParent.childComponents.findIndex((v) => v === widget);
    if (widgetIndex !== undefined && widgetIndex >= 0 && widgetIndex <= targetParent.childComponents.length) {
      targetParent.childComponents.splice(widgetIndex, 0, widget);
    } else {
      (targetParent.childComponents as NewWorkflowWidgetConfig[]).push(widget);
    }
  }

  return widgets;
}

export function moveWidget(
  widgetId: string | number,
  targetParentId: string | number | typeof TOP_LEVEL_ID,
  widgets: NewWorkflowWidgetConfig[],
  targetIndex?: number,
  target2dChildComponentIndex?: number,
): NewWorkflowWidgetConfig[] {
  // Find the widget to move
  const widgetToMove = findWidget(widgetId, widgets);
  if (!widgetToMove) {
    console.error('Widget to move not found');
    return widgets;
  }

  deleteWidget(widgetId, widgets);

  return addWidget(widgetToMove, targetParentId, widgets, targetIndex, target2dChildComponentIndex);
}

export function flattenWorkflowWidgets(widgets: NewWorkflowWidgetConfig[]): NewWorkflowWidgetConfig[] {
  const flattened: NewWorkflowWidgetConfig[] = [];

  function flatten(item: NewWorkflowWidgetConfig | NewWorkflowWidgetConfig[]) {
    if (Array.isArray(item)) {
      item.forEach(flatten);
    } else {
      flattened.push(item);
      if (item.childComponents) {
        if (Array.isArray(item.childComponents[0])) {
          (item.childComponents as NewWorkflowWidgetConfig[][]).forEach(flatten);
        } else {
          flatten(item.childComponents as NewWorkflowWidgetConfig[]);
        }
      }
    }
  }

  flatten(widgets);
  return flattened;
}
