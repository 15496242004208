export const exchangeTransactionHistoryItemTemplate = `
<div class="transaction w-100 border-bottom">
    <div class="ml-1 mr-1 mt-2">
        <h3 class="text-left m-0"> {{ description }} </h3>
        <p class="text-left transaction-date m-0"> {{ status }}. {{ datePipe transactionDate  format='d MMM y' }} </p>
    </div>
    <div class="is-right">
        <h3 class="text-right m-0">{{ currencyPipe amount currencyCode=currency display='symbol-narrow' }}</h3>
    </div>
</div>
`;

export const cardTransactionHistoryItemTemplate = `
<div class="is-space-between w-100 pb-4">
  <div class="d-flex align-items-center">
    <div class="mr-3 d-flex svg-background">
      {{#if (eq type "atm-withdrawal")}}
        {{{ svgIcon 'money-simple-from-bracket-p' '20px' '20px' }}}
      {{/if}}
      {{#if (eq type "chargeback")}}
        {{{ svgIcon 'refund-p' '20px' '20px' }}}
      {{/if}}
      {{#if (eq type "payment")}}
        {{{ svgIcon 'shopping-bag-p' '20px' '20px' }}}
      {{/if}}
      {{#if (eq type "refund")}}
        {{{ svgIcon 'refund-p' '20px' '20px' }}}
      {{/if}}
      {{#if (eq type "money-transfer")}}
        {{{ svgIcon 'top-up-p' '20px' '20px' }}}
      {{/if}}
    </div>
    <div class="mr-1 flex-grow-1">
      <div class="text-left text-capitalize body1 m-0">{{ merchant.name }}</div>
      <p class="text-left subtitle2 transaction-date text-capitalize m-0 pt-1">
        {{ status }} | {{ datePipe creationDateTime format='d MMM y' }}
      </p>
    </div>
  </div>
  <div class="text-right">
    <div class="body1 m-0">
      {{ currencyPipe totalAmount currencyCode=transactionCurrency display='symbol-narrow' }}
    </div>
    <p class="subtitle2 transaction-date m-0 pt-1">
       {{ currencyPipe originalCurrencyAmount currencyCode=originalCurrency display='symbol-narrow' }}
    </p>
  </div>
</div>

`;
