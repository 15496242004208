import { Injectable, computed, signal } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonUiZoneWidgetConfig } from '@finxone-platform/shared/sys-config-types';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export interface FormFieldConfig {
  type: 'text' | 'number' | 'email' | 'select' | 'group' | 'boolean';
  name: string;
  label: string;
  validation?: {
    required?: boolean;
    requiredTrue?: boolean;
    minLength?: number;
    maxLength?: number;
    pattern?: string;
  };
  options?: { value: string | number; label: string }[];
  fields?: FormFieldConfig[];
  defaultValue?: any; // Useful for setting initial values, especially for booleans
}

@Injectable({
  providedIn: 'root',
})
export class WorkflowFormsService {
  private readonly fb: FormBuilder = new FormBuilder();
  forms = signal<Map<string, FormGroup>>(new Map());
  private formValidity = signal<Map<string, boolean>>(new Map());

  // BehaviorSubject to track form changes
  private formsSubject = new BehaviorSubject<Map<string, FormGroup>>(this.forms());

  constructor() {}
  private setupFormValidityTracking(path: string, form: FormGroup) {
    form.valueChanges.subscribe(() => {
      const newMap = new Map(this.formValidity());
      newMap.set(path, this.isFormValid(path));
      this.formValidity.set(newMap);
    });

    // Initialize validity
    const newMap = new Map(this.formValidity());
    newMap.set(path, this.isFormValid(path));
    this.formValidity.set(newMap);
  }

  /**
   * Gets a nested form group based on a dot-notation path
   * @param path Path to the form group (e.g., 'onboarding.page1')
   * @returns FormGroup if found, undefined otherwise
   */
  getFormPart(path: string): FormGroup | undefined {
    const parts = path.split('.');
    let currentForm: FormGroup | undefined = undefined;

    // Handle root level form
    if (parts.length === 1) {
      return this.forms().get(parts[0]);
    }

    // Navigate through nested form groups
    currentForm = this.forms().get(parts[0]);
    if (!currentForm) return undefined;

    for (let i = 1; i < parts.length; i++) {
      currentForm = currentForm.get(parts[i]) as FormGroup;
      if (!currentForm) return undefined;
    }

    return currentForm;
  }

  /**
   * Creates a form group and any necessary parent groups based on the path
   * @param path Path where to create the form (e.g., 'onboarding.page1')
   * @param config Form configuration
   */
  createForm(path: string, config: FormFieldConfig[]): void {
    const parts = path.split('.');
    const rootName = parts[0];

    // Create or get root form group
    let rootForm = this.forms().get(rootName);
    if (!rootForm) {
      rootForm = this.fb.group({});
      this.forms().set(rootName, rootForm);
      this.setupFormValidityTracking(path, rootForm);
    }

    // Build nested structure
    let currentForm = rootForm;
    for (let i = 1; i < parts.length; i++) {
      const part = parts[i];
      if (!currentForm.contains(part)) {
        currentForm.addControl(part, this.fb.group({}));
      }
      currentForm = currentForm.get(part) as FormGroup;
    }

    // Add form controls based on config
    const newFormGroup = this.createFormGroupFromConfig(config);
    Object.keys(newFormGroup.controls).forEach((key) => {
      if (!currentForm.contains(key)) {
        currentForm.addControl(key, newFormGroup.get(key));
      } else {
        currentForm.setControl(key, newFormGroup.get(key));
      }
    });

    this.formsSubject.next(this.forms());
  }

  /**
   * Clears all forms from the service
   */
  clearAllForms(): void {
    this.forms().clear();
    this.formsSubject.next(this.forms());
  }

  /**
   * Observes changes to a specific form path
   * @param path Path to the form to observe
   */
  observeForm(path: string): Observable<FormGroup | undefined> {
    return this.formsSubject.pipe(map(() => this.getFormPart(path)));
  }

  /**
   * Updates values in a specific form path
   * @param path Path to the form
   * @param values Values to patch
   */
  patchFormValues(path: string, values: any): void {
    const form = this.getFormPart(path);
    if (form) {
      form.patchValue(values, { emitEvent: true });
    }
  }

  /**
   * Validates a specific form path
   * @param path Path to the form to validate
   * @returns boolean indicating if the form is valid
   */
  isFormValid(path: string): boolean {
    const form = this.getFormPart(path);
    return form ? form.valid : false;
  }

  /**
   * Gets all validation errors for a specific form path
   * @param path Path to the form
   * @returns Object containing all validation errors
   */
  getFormErrors(path: string): { [key: string]: any } {
    const form = this.getFormPart(path);
    if (!form) return {};

    return this.getControlErrors(form);
  }

  /**
   * Resets a specific form to its initial state
   * @param path Path to the form to reset
   */
  resetForm(path: string): void {
    const form = this.getFormPart(path);
    if (form) {
      form.reset();
    }
  }

  /**
   * Creates a form group from the provided configuration
   * @param config Form field configuration
   * @returns FormGroup
   */
  private createFormGroupFromConfig(config: FormFieldConfig[]): FormGroup {
    const group: any = {};

    config.forEach((field) => {
      if (field.type === 'group' && field.fields) {
        group[field.name] = this.createFormGroupFromConfig(field.fields);
      } else {
        group[field.name] = [field?.defaultValue ?? '', this.createValidators(field.validation)];
      }
    });

    return this.fb.group(group);
  }

  /**
   * Creates validators array from validation config
   * @param validation Validation configuration
   * @returns Array of validators
   */
  private createValidators(validation: any = {}) {
    const validators = [];

    if (validation?.required) {
      validators.push(Validators.required);
    }
    if (validation?.minLength) {
      validators.push(Validators.minLength(validation.minLength));
    }
    if (validation?.maxLength) {
      validators.push(Validators.maxLength(validation.maxLength));
    }
    if (validation?.pattern) {
      validators.push(Validators.pattern(validation.pattern));
    }
    if (validation?.requiredTrue) {
      validators.push(Validators.requiredTrue);
    }

    return validators;
  }

  /**
   * Recursively gets all errors from a form control and its children
   * @param control Form control to check for errors
   * @returns Object containing all errors
   */
  private getControlErrors(control: AbstractControl): { [key: string]: any } {
    const errors: { [key: string]: any } = {};

    if (control instanceof FormGroup) {
      Object.keys(control.controls).forEach((key) => {
        const childErrors = this.getControlErrors(control.get(key)!);
        if (Object.keys(childErrors).length > 0) {
          errors[key] = childErrors;
        }
      });
    } else {
      if (control.errors) {
        return control.errors;
      }
    }

    return errors;
  }

  /**
   * Gets the complete value of a form at a specific path
   * @param path Path to the form
   * @returns Form values or undefined if form not found
   */
  getFormValues(path: string): any {
    const form = this.getFormPart(path);
    return form ? form.value : undefined;
  }

  /**
   * Marks all controls in a form as touched
   * @param path Path to the form
   */
  markFormTouched(path: string): void {
    const form = this.getFormPart(path);
    if (form) {
      Object.values(form.controls).forEach((control) => {
        control.markAsTouched();
        if (control instanceof FormGroup) {
          this.markFormGroupTouched(control);
        }
      });
    }
  }

  /**
   * Recursively marks all controls in a form group as touched
   * @param formGroup Form group to mark as touched
   */
  private markFormGroupTouched(formGroup: FormGroup): void {
    Object.values(formGroup.controls).forEach((control) => {
      control.markAsTouched();
      if (control instanceof FormGroup) {
        this.markFormGroupTouched(control);
      }
    });
  }

  // Track current page's form paths
  private currentPageForms = signal<string[]>([]);
  public currentPageFormsValid = computed(() => {
    return this.currentPageForms()
      .map((form) => {
        return this.formValidity().get(form);
      })
      .every((isValid) => isValid);
  });

  /**
   * Register forms that are currently active on the page
   * @param formPaths Array of form paths active on the current page
   */
  registerCurrentPageForms(formPaths: string[]): void {
    this.currentPageForms.set(formPaths);
  }

  /**
   * Clear current page forms when navigating away
   */
  clearCurrentPageForms(): void {
    this.currentPageForms.set([]);
  }

  /**
   * Check if all current page forms are valid
   * @returns Object containing validation status and invalid forms
   */
  validateCurrentPageForms(): {
    isValid: boolean;
    invalidForms: { path: string; errors: any }[];
  } {
    const invalidForms = this.currentPageForms()
      .filter((path) => !this.isFormValid(path))
      .map((path) => ({
        path,
        errors: this.getFormErrors(path),
      }));

    return {
      isValid: invalidForms.length === 0,
      invalidForms,
    };
  }

  /**
   * Mark all forms on the current page as touched
   */
  markCurrentPageFormsTouched(): void {
    this.currentPageForms().forEach((path) => {
      this.markFormTouched(path);
    });
  }
}

/**
 * Extracts form paths from widget configurations with optional type filtering
 * @param widgets List of widget configurations
 * @param formType Optional type to filter ('self' or 'page')
 * @returns Array of unique form paths
 */
export const extractFormPaths = (
  widgets: CommonUiZoneWidgetConfig[],
  formType?: 'self' | 'page',
): string[] => {
  const formPaths = widgets.reduce<string[]>((paths, widget) => {
    // Check main widget's workflowFormConfig
    if (
      widget.attributes.workflowFormConfig?.formPath &&
      (!formType || widget.attributes.workflowFormConfig.type === formType)
    ) {
      paths.push(widget.attributes.workflowFormConfig.formPath);
    }

    // Check nested widgets if they exist
    if (widget.nestedWidgets) {
      Object.values(widget.nestedWidgets).forEach((nestedWidget) => {
        if (
          nestedWidget.workflowFormConfig?.formPath &&
          (!formType || nestedWidget.workflowFormConfig.type === formType)
        ) {
          paths.push(nestedWidget.workflowFormConfig.formPath);
        }
      });
    }

    return paths;
  }, []);

  // Remove duplicates and filter out empty strings
  return [...new Set(formPaths)].filter(Boolean);
};
