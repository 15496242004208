import { Router } from '@angular/router';
import { APP_ZONES } from '@finxone-platform/shared/sys-config-types';
import { formatNameAsUrl } from '../../zone-url.utils';

export const showEPINUtil = (router: Router) => {
  const targetUrl = `/zones/${formatNameAsUrl(APP_ZONES.CARD_MANAGEMENT_ZONE)}/show-pin`;

  router.navigate(['confirmation-code'], {
    queryParams: {
      claimType: 'getEpin',
      confirmationType: 'PIN',
      returnUrl: targetUrl,
    },
  });
};
