import { Injectable } from '@angular/core';
import { KeycloakWrapperService } from './keycloak-wrapper.service';
import { ConfirmationRequest } from './keycloak-custom.type';

@Injectable({
  providedIn: 'root',
})
export class KeycloakCustomService {
  constructor(private keycloakWrapper: KeycloakWrapperService) {}

  public async sendConfirmation(payload: ConfirmationRequest): Promise<{ access_token: string }> {
    const { claim, factors } = payload;
    return this.keycloakWrapper.useCustomKeycloakService<{ access_token: string }>(
      'POST',
      'confirmation/confirmation',
      {
        data: {
          claim,
          factors,
        },
        isExternalKeycloak: true,
      },
    );
  }
}
