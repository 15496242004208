import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AddBeneficiaryRequest } from '@app/finxone-web-frontend/app/lib/services/account-service/account.type';
import { FormSubmissionService } from '@app/finxone-web-frontend/app/lib/services/form-submission-service/form-submission-service.service';
import {
  FormActionState,
  FormActionType,
} from '@app/finxone-web-frontend/app/lib/state/form-submision.state';
import { kebabToCamelCase } from '@app/finxone-web-frontend/app/lib/utils/utils';
import { nameRegex, noNumbersValidator } from '@app/finxone-web-frontend/app/lib/utils/validators.utils';
import { FormActionTypeEnum } from '@finxone-platform/form-action';
import { AutoDestroy } from '@finxone-platform/shared/base-types';
import {
  ACCOUNTTYPE,
  BaseWidgetProperties,
  BeneficiaryBankRequirements,
  baseCssConfigStyle,
} from '@finxone-platform/shared/sys-config-types';
import { Select } from '@ngxs/store';
import { DragScrollComponent } from 'ngx-drag-scroll';

import { GetBeneficiaryRequiredFieldsAction } from '@app/finxone-web-frontend/app/lib/actions/beneficiary.action';
import { BeneficiaryState } from '@app/finxone-web-frontend/app/lib/state/beneficiary.state';
import { ProjectSettingsState } from '@app/finxone-web-frontend/app/lib/state/project-settings.state';
import { Store } from '@ngxs/store';
import { ValidationConfig } from 'libs/ui-components/src/lib/finxone-input/input-validations-factory';
import { Observable, Subject, debounceTime, map, takeUntil } from 'rxjs';
import { BaseComponent } from '../base/base.component';
import { AccountRequirements } from '../international-beneficiary-credentials-widget/international-beneficiary-credentials-widget.component';

export type InternationBeneficiaryFormStateModel = {
  name?: string;
  firstName?: string;
  lastName?: string;
  accountType: ACCOUNTTYPE;
  currency: string;
  countryIso2: string;
  beneficiaryAddress: 'required' | 'optional';
  accountNumber?: string;
  sortCode?: string;
  iban?: string;
  bic?: string;
  aba?: string;
  rtnCanada?: string;
  clabe?: string;
  bsbCode?: string;
  ifsc?: string;
  cnaps?: string;
  addressCountry: string;
  addressStreet: string;
  addressCity: string;
  addressPostCode: string;
  addressState: string;
};

export function mapInternationBeneficiaryFormStateModelToAddBeneficiaryRequest(
  form: InternationBeneficiaryFormStateModel,
): AddBeneficiaryRequest {
  return {
    name: form.name ?? '',
    accountType: form.accountType,
    destinationIdentifier: {
      ...form,
      country: form.countryIso2,
    },
    address: {
      addressLine1: form.addressStreet,
      city: form.addressCity,
      country: form.addressCountry,
      postCode: form.addressPostCode,
      state: form.addressState,
    },
  };
}

@Component({
  selector: 'finxone-web-frontend-international-beneficiary-credentials',
  templateUrl: './international-beneficiary-credentials.component.html',
  styleUrls: ['./international-beneficiary-credentials.component.scss'],
})
export class InternationalBeneficiaryCredentialsComponent extends BaseComponent implements OnInit {
  @AutoDestroy destroy$: Subject<void> = new Subject<void>();

  @ViewChild('beneficiaryDetailsFieldsCarousel', { read: DragScrollComponent })
  ds: DragScrollComponent;

  @Input() public countryCode: string;
  @Input() public currencyCode: string;
  @Input() public recipientName: string;
  @Input() public firstName: string;
  @Input() public lastName: string;
  @Input() addressRequired: string;
  @Input() beneficiaryType: string;
  @Input() continueButtonStyle: baseCssConfigStyle = {};
  @Input() previousButtonStyle: baseCssConfigStyle = {};
  @Input() nextButtonStyle: baseCssConfigStyle = {};
  @Input() continueButtonContent = 'Continue';
  @Input() previousButtonContent = 'Previous';
  @Input() nextButtonContent = 'Next';
  @Input() override contentStyle: baseCssConfigStyle;
  @Input() public widgetProperties: BaseWidgetProperties;
  private formStateValues: Record<string, string>;
  @Input() public isSubmitTypeAsynchronous = false;

  @Output() public valuesChangedEvent = new EventEmitter();
  @Input() buttonGlobalStyles = {
    continueButton: {
      globalStylingClass: '',
      isAllowedGlobalStyling: false,
    },
    previousButton: {
      globalStylingClass: '',
      isAllowedGlobalStyling: false,
    },
    nextButton: {
      globalStylingClass: '',
      isAllowedGlobalStyling: false,
    },
  };

  @Select(FormActionState.getFormActionStateWithId(FormActionTypeEnum.INTL_BENEFICIARY_ADD))
  formAction!: Observable<FormActionType>;

  public fieldsList: {
    label: string;
    title: string;
    item: { regex?: RegExp; requirement: string };
    validated?: boolean;
    currentStyle: baseCssConfigStyle;
  }[] = [];
  public currentIndex = 0;
  public intlBeneficiaryCredentialsForm: FormGroup;
  public currentStyle: baseCssConfigStyle;

  public requirements: BeneficiaryBankRequirements;
  public nextBtnFailed = false;
  public filteredRequirements: AccountRequirements;
  isValueChanged: boolean;
  public enableBeneficiaryFirstAndLastName = false;
  public fieldsValidationConfig: { [key: string]: ValidationConfig[] } = {};

  constructor(
    private formBuilder: FormBuilder,
    private formSubmissionService: FormSubmissionService,
    private _router: Router,
    private store: Store,
  ) {
    super();
    this.intlBeneficiaryCredentialsForm = this.formBuilder.group({});
  }

  ngOnInit() {
    this.enableBeneficiaryFirstAndLastName = this.store.selectSnapshot(
      ProjectSettingsState.getProjectSettings,
    )?.enableBeneficiaryFirstAndLastName;

    this.setupRequirementsBasedOnCountryCurrencyPair();
  }
  private setupRequirementsBasedOnCountryCurrencyPair() {
    this.formAction.pipe(takeUntil(this.destroy$)).subscribe((formValues: FormActionType) => {
      this.formStateValues = formValues?.formData;

      if (!this.enableBeneficiaryFirstAndLastName) {
        this.formStateValues = formValues?.formData;
      } else {
        const name = formValues?.formData?.['name']?.trim();
        let firstName = '';
        let lastName = '';

        if (name) {
          const fullName = name.split(' ');

          if (fullName?.length > 1) {
            lastName = fullName[fullName?.length - 1];
            firstName = fullName.slice(0, -1).join(' '); // All words except the last as given name
          } else {
            firstName = fullName[0];
          }
        }
        this.formStateValues = {
          ...formValues?.formData,
          firstName: firstName,
          lastName: lastName,
        };
      }

      if (this.formStateValues['countryIso2'] && this.formStateValues['currency']) {
        this.countryCode = this.formStateValues['countryIso2'];
        this.currencyCode = this.formStateValues['currency'];
      } else {
        this.countryCode = 'GB';
        this.currencyCode = 'GBP';
      }
      if (Object.keys(this.formStateValues).length && !this.requirements) this.getRequiredFields();
    });
  }
  public onIndexChanged(idx: number) {
    this.currentIndex = idx;
  }
  private generateForm() {
    this.intlBeneficiaryCredentialsForm = this.formBuilder.group({});
    const controlNames = Object.keys(this.intlBeneficiaryCredentialsForm.controls);
    if (this.fieldsList.length > 0) {
      controlNames.forEach((name) => this.intlBeneficiaryCredentialsForm.removeControl(name));
      for (const field of this.fieldsList) {
        const validators = [];
        const validatorConfigForInput = [];
        if (field.item.requirement === 'required') {
          validators.push(Validators.required);
          validatorConfigForInput.push({
            type: 'required',
            message: '',
          });
        }
        if (field.item.regex) {
          validators.push(Validators.pattern(field.item.regex));
          validatorConfigForInput.push({
            type: 'pattern',
            message: 'Entered value is not in correct format',
            params: field.item.regex,
          });
        }
        if (
          (field.title === 'lastName' || field.title === 'firstName' || field.title === 'name') &&
          this.beneficiaryType === ACCOUNTTYPE.PERSONAL
        ) {
          validators.push(noNumbersValidator());
          validatorConfigForInput.push({
            type: 'custom',
            message: 'Only alphabetic characters are allowed',
            validatorFn: noNumbersValidator(),
          });
        }
        this.intlBeneficiaryCredentialsForm.addControl(
          field.title,
          new FormControl(this.formStateValues[field.title], validators),
        );
        this.fieldsValidationConfig[field.title] = validatorConfigForInput;
      }
      if (this.isSubmitTypeAsynchronous) {
        this.subscribeToFormChangesAndEmitValues();
      }
    }
  }
  private subscribeToFormChangesAndEmitValues() {
    this.intlBeneficiaryCredentialsForm.valueChanges
      .pipe(
        takeUntil(this.destroy$),
        debounceTime(600),
        map(() => {
          const formObject = this.intlBeneficiaryCredentialsForm.value;
          if ('firstName' in formObject && 'lastName' in formObject) {
            const firstName = formObject['firstName'];
            const lastName = formObject['lastName'];
            const fullName = firstName + ' ' + lastName;
            // Remove firstName and lastName from formObject
            delete formObject['firstName'];
            delete formObject['lastName'];
            // Add fullName under name key
            formObject['name'] = fullName;
          }

          if (this.intlBeneficiaryCredentialsForm.valid || this.isValueChanged) {
            this.isValueChanged = false;
            this.valuesChangedEvent.emit({
              ...formObject,
              isCredentialsFormValid: this.intlBeneficiaryCredentialsForm.valid,
            });
          }
        }),
      )
      .subscribe();
  }

  private createLabelFromTitle(title: string) {
    // Remove special characters
    const sanitizedString = title.replace(/[^a-zA-Z ]/g, ' ');

    // Capitalize the first letter
    let formattedString = sanitizedString.charAt(0).toUpperCase();

    // Loop through the rest of the characters
    for (let i = 1; i < sanitizedString.length; i++) {
      if (sanitizedString[i] === sanitizedString[i].toUpperCase()) {
        formattedString += ' ';
      }
      // Ensure the letter following a space is uppercase
      if (sanitizedString[i - 1] === ' ') {
        formattedString += sanitizedString[i].toUpperCase();
      } else {
        formattedString += sanitizedString[i];
      }
    }

    return formattedString;
  }
  private createFormObject() {
    let formObject: any = {
      beneficiaryAddress: this.addressRequired,
      accountType: this.beneficiaryType,
    };
    for (const [key, value] of Object.entries(this.intlBeneficiaryCredentialsForm.value)) {
      if (key === 'Business Name') {
        const nameKey = 'fullName';
        formObject = { ...formObject, [nameKey]: value };
      } else {
        formObject = { ...formObject, [key]: value };
      }
    }
    return formObject;
  }
  public onSubmit() {
    const formObject = this.createFormObject();

    if ('firstName' in formObject && 'lastName' in formObject) {
      const firstName = formObject['firstName'];
      const lastName = formObject['lastName'];
      const fullName = firstName + ' ' + lastName;
      // Remove firstName and lastName from formObject
      delete formObject['firstName'];
      delete formObject['lastName'];
      // Add fullName under name key
      formObject['name'] = fullName;
    }

    if (this.intlBeneficiaryCredentialsForm.status === 'VALID') {
      this.formSubmissionService.submitIndividualForm(
        formObject,
        '',
        FormActionTypeEnum.INTL_BENEFICIARY_ADD,
      );
      this._router.navigateByUrl('/zones/payments/intl-beneficiary-address');
    } else {
      this.intlBeneficiaryCredentialsForm.markAllAsTouched();
    }
  }
  get currentFieldAndCount() {
    return ' (' + (this.currentIndex + 1) + '/' + this.fieldsList?.length + ')';
  }

  goToPreviousField() {
    this.ds.moveLeft();
  }
  goToNextField() {
    if (this.isFormFieldValid) {
      this.ds.moveRight();
    } else {
      const fieldName = this.fieldsList[this.currentIndex].title;
      const field = this.intlBeneficiaryCredentialsForm.get(fieldName);
      if (field) {
        field.markAsDirty();
        field.markAsDirty();
      }
    }
  }
  checkForErrors() {
    if (this.isFormFieldValid) {
      this.nextBtnFailed = false;
    } else {
      this.nextBtnFailed = true;
    }
  }

  getErrorMessage() {
    const field = this.intlBeneficiaryCredentialsForm.get(this.fieldsList[this.currentIndex].title);
    if (field?.errors && field?.errors['required']) {
      return 'This field is mandatory';
    } else if (field?.errors && field?.errors['noNumbers']) {
      return 'Name cannot include numbers';
    } else {
      return 'Invalid format. Please check and try again';
    }
  }

  get isFormFieldValid() {
    const currentField = this.fieldsList[this.currentIndex]?.title;
    return this.intlBeneficiaryCredentialsForm.get(currentField)?.valid;
  }

  onSetValidationStyleOnInput() {
    if (!this.isFormFieldValid) {
      const fieldName = this.fieldsList[this.currentIndex].title;
      const field = this.intlBeneficiaryCredentialsForm.get(fieldName);
      if (field) {
        field.markAsDirty();
        field.markAsDirty();
      }
    }
    return this.isFormFieldValid;
  }

  public onKeyDown(event: any) {
    if (event.key === 'Tab') {
      event.preventDefault();
    }
  }

  private getRequiredFields() {
    this.store.dispatch(new GetBeneficiaryRequiredFieldsAction(this.countryCode, this.currencyCode));

    this.store
      .select(BeneficiaryState.getBeneficiaryRequiredFields())
      .pipe(
        takeUntil(this.destroy$),
        map((data) => {
          if (!data?.beneficiaryBankAccount) {
            return;
          }
          // new requirements need to reset
          this.fieldsList = [];
          this.filteredRequirements = {};
          this.requirements = data?.beneficiaryBankAccount;
          this.filterNonRequiredFields(this.requirements);
          for (const [key, value] of Object.entries(this.filteredRequirements)) {
            for (const widgetKey in this.widgetProperties) {
              if (Object.hasOwn(this.widgetProperties, widgetKey)) {
                const widgetLabel = this.widgetProperties[widgetKey];
                const formattedKey = kebabToCamelCase(widgetKey);
                if (formattedKey === key) {
                  if (!this.fieldsList.some((field) => field.title === key)) {
                    this.fieldsList.push({
                      label: widgetLabel,
                      title: key,
                      item: value,
                      currentStyle: this.currentStyle,
                    });
                  }
                }
              }
            }
          }
          this.generateForm();
        }),
      )
      .subscribe();
  }

  private filterNonRequiredFields(required: object) {
    if (this.beneficiaryType === ACCOUNTTYPE.PERSONAL) {
      if (!this.enableBeneficiaryFirstAndLastName) {
        this.filteredRequirements = {
          ...this.filteredRequirements,
          [this.recipientName]: {
            requirement: 'required',
            regex: nameRegex,
          },
        };
      } else {
        this.filteredRequirements = {
          ...this.filteredRequirements,
          [this.firstName]: {
            requirement: 'required',
            regex: nameRegex,
          },
          [this.lastName]: {
            requirement: 'required',
            regex: nameRegex,
          },
        };
      }
    } else {
      this.filteredRequirements = {
        ...this.filteredRequirements,
        [this.recipientName]: {
          requirement: 'required',
        },
      };
    }

    for (const [key, value] of Object.entries(required)) {
      //routingCodes is nested so get each entry from routingCodes
      if (key === 'routingCodes') {
        for (const [k, v] of Object.entries(value)) {
          const formattedKey = kebabToCamelCase(k);
          this.filteredRequirements = {
            ...this.filteredRequirements,
            [formattedKey]: v,
          };
        }
      } else if (key === 'requirements') {
        this.addressRequired = value['beneficiaryAddress'];
      } else if (value['requirement'] !== 'none') {
        this.filteredRequirements = {
          ...this.filteredRequirements,
          [key]: value,
        };
      }
    }
  }
  public valueChangeTrigger() {
    this.isValueChanged = true;
    this.nextBtnFailed = false;
  }
}
