import { Injectable } from '@angular/core';
import {
  CardStatusType,
  CardTransactionDetail,
  CardTransactionInfoType,
  ChangePinRequest,
  PaginatedResponse,
} from '@finxone-platform/shared/sys-config-types';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Observable, catchError, map, tap, throwError } from 'rxjs';
import {
  CancelCardAction,
  CardActivateAction,
  ChangePin,
  DeleteVirtualCardAction,
  FreezeCard,
  GetCVVDetails,
  GetCVVDetailsSuccess,
  GetCardSecureToken,
  GetCardSecureTokenSuccess,
  GetCardTransactionDetailById,
  GetCardTransactions,
  LostCard,
  MakePhysicalCard,
  MakeVirtualCard,
  ReplaceCard,
  SetCardNameAction,
  UnFreezeCard,
  UpdateCardManagement,
  ViewPin,
  ViewPinSuccess,
} from '../actions/card-management.action';
import { GetCardDetails, GetCards, UpdateCurrentCardDetail } from '../actions/card.actions';
import { AddProgressBarStack, RemoveProgressBarStack } from '../actions/progress-bar.action';
import { CardManagementService } from '../services/card-management-service/card-management-service';
import { ApiActions, UpdateApiIsLoadingAction } from './api-loading.state';

export interface CardManagementStateModel {
  isFrozen: boolean;
  cardTransactionDetail: PaginatedResponse<CardTransactionInfoType>;
  isLoading: boolean;
  currentCardTransactionDetail: CardTransactionDetail;
}

@State<CardManagementStateModel>({
  name: 'cardManagement',
  defaults: {
    isFrozen: false,
    cardTransactionDetail: {
      result: [],
      totalPages: 2,
      page: 0,
      limit: 0,
    },
    isLoading: false,
    currentCardTransactionDetail: {} as CardTransactionDetail,
  },
})
@Injectable()
export class CardManagementState {
  constructor(private cardManagementService: CardManagementService) {}

  @Selector()
  static getCardManagementState(state: CardManagementStateModel) {
    return state;
  }

  @Selector()
  static getLoadingStatus(state: CardManagementStateModel) {
    return state.isLoading;
  }

  @Action(UpdateCardManagement)
  updateCardManagement(ctx: StateContext<CardManagementStateModel>, action: UpdateCardManagement) {
    let isCardFrozen = false;
    if (action.payload.cardStatus === CardStatusType.FROZEN) {
      isCardFrozen = true;
    }
    ctx.patchState({
      ...ctx.getState(),
      isFrozen: isCardFrozen,
    });
  }

  @Action(FreezeCard, { cancelUncompleted: true })
  freezeCard(ctx: StateContext<CardManagementStateModel>, action: FreezeCard) {
    ctx.dispatch(new AddProgressBarStack({ uniqueId: 'FreezeCard' }));
    return this.cardManagementService.freezeCard(action.payload).pipe(
      tap(() => {
        ctx.dispatch(
          new GetCardDetails({
            accountId: action.payload.accountId,
            cardId: action.payload.cardId,
          }),
        );
        ctx.dispatch(new RemoveProgressBarStack({ uniqueId: 'FreezeCard' }));
      }),
      catchError((_err) => {
        ctx.dispatch(new RemoveProgressBarStack({ uniqueId: 'FreezeCard' }));
        throw _err;
      }),
    );
  }

  @Action(UnFreezeCard, { cancelUncompleted: true })
  unfreezeCard(ctx: StateContext<CardManagementStateModel>, action: UnFreezeCard) {
    ctx.dispatch(new AddProgressBarStack({ uniqueId: 'UnFreezeCard' }));
    return this.cardManagementService.unfreezeCard(action.payload).pipe(
      tap(() => {
        ctx.dispatch(
          new GetCardDetails({
            accountId: action.payload.accountId,
            cardId: action.payload.cardId,
          }),
        );
        ctx.dispatch(new RemoveProgressBarStack({ uniqueId: 'UnFreezeCard' }));
      }),
      catchError((_err) => {
        ctx.dispatch(new RemoveProgressBarStack({ uniqueId: 'UnFreezeCard' }));
        throw _err;
      }),
    );
  }

  @Action(ReplaceCard, { cancelUncompleted: true })
  replaceCard(ctx: StateContext<CardManagementStateModel>, action: ReplaceCard) {
    ctx.dispatch(new AddProgressBarStack({ uniqueId: 'ReplaceCard' }));
    return this.cardManagementService.replaceCard(action.payload).pipe(
      map((response) => {
        ctx.dispatch(new UpdateCurrentCardDetail(response, action.payload.cardId));
      }),
      tap(() => {
        ctx.dispatch(new RemoveProgressBarStack({ uniqueId: 'ReplaceCard' }));
      }),
      catchError((_err) => {
        ctx.dispatch(new RemoveProgressBarStack({ uniqueId: 'ReplaceCard' }));
        throw _err;
      }),
    );
  }

  @Action(GetCardSecureToken, { cancelUncompleted: true })
  getCardSecureToken(ctx: StateContext<CardManagementStateModel>, action: GetCardSecureToken) {
    ctx.dispatch(new AddProgressBarStack({ uniqueId: 'GetCardSecureToken' }));
    return this.cardManagementService.getCardSecureToken(action.payload).pipe(
      map((cardToken) => {
        const encryptedSymmetricKey = cardToken.encryptedSymmetricKey;
        const encodedIv = cardToken.initialisationVector;
        const encryptedToken = cardToken.token;

        ctx.dispatch(
          new GetCardSecureTokenSuccess({
            encryptedToken,
            encryptedSymmetricKey,
            encodedIv,
          }),
        );
      }),
      tap(() => {
        ctx.dispatch(new RemoveProgressBarStack({ uniqueId: 'GetCardSecureToken' }));
      }),
      catchError<unknown, Observable<boolean>>((_err) => {
        ctx.dispatch(new RemoveProgressBarStack({ uniqueId: 'GetCardSecureToken' }));
        throw _err;
      }),
    );
  }

  @Action(ChangePin, { cancelUncompleted: true })
  changePin(ctx: StateContext<CardManagementStateModel>, action: ChangePin) {
    ctx.dispatch(new AddProgressBarStack({ uniqueId: 'ChangePin' }));
    return this.cardManagementService
      .changePin({
        oldPin: action.payload.oldPin,
        newPin: action.payload.newPin,
        secureToken: action.payload.secureToken,
        accountId: action.payload.accountId,
        cardId: action.payload.cardId,
      } as ChangePinRequest)
      .pipe(
        tap(() => {
          ctx.dispatch(
            new GetCardDetails({
              accountId: action.payload.accountId,
              cardId: action.payload.cardId,
            }),
          );
          ctx.dispatch(new RemoveProgressBarStack({ uniqueId: 'ChangePin' }));
        }),
        catchError((_err) => {
          ctx.dispatch(new RemoveProgressBarStack({ uniqueId: 'ChangePin' }));
          throw _err;
        }),
      );
  }

  @Action(CardActivateAction, { cancelUncompleted: true })
  activateCard(ctx: StateContext<CardManagementStateModel>, action: CardActivateAction) {
    ctx.dispatch(new AddProgressBarStack({ uniqueId: 'CardActivateAction' }));
    return this.cardManagementService.activateCard(action.payload).pipe(
      tap(() => {
        ctx.dispatch(
          new GetCardDetails({
            accountId: action.payload.accountId,
            cardId: action.payload.cardId,
          }),
        );
        ctx.dispatch(new RemoveProgressBarStack({ uniqueId: 'CardActivateAction' }));
      }),
      catchError((_err) => {
        ctx.dispatch(new RemoveProgressBarStack({ uniqueId: 'CardActivateAction' }));
        throw _err;
      }),
    );
  }

  @Action(GetCVVDetails, { cancelUncompleted: true })
  getCVVDetails(ctx: StateContext<CardManagementStateModel>, action: GetCVVDetails) {
    ctx.dispatch(new AddProgressBarStack({ uniqueId: 'GetCVVDetails' }));
    return this.cardManagementService.getCVVDetails(action.payload).pipe(
      map((response) => {
        ctx.dispatch(new GetCVVDetailsSuccess(response));
      }),
      tap(() => {
        ctx.dispatch(new RemoveProgressBarStack({ uniqueId: 'GetCVVDetails' }));
      }),
      catchError((_err) => {
        ctx.dispatch(new RemoveProgressBarStack({ uniqueId: 'GetCVVDetails' }));
        throw _err;
      }),
    );
  }

  @Action(LostCard, { cancelUncompleted: true })
  lostCard(ctx: StateContext<CardManagementStateModel>, action: LostCard) {
    ctx.dispatch(new AddProgressBarStack({ uniqueId: 'LostCard' }));
    return this.cardManagementService.replaceCard(action.formData).pipe(
      map((response) => {
        ctx.dispatch(new UpdateCurrentCardDetail(response, action.formData.cardId));
      }),
      tap(() => {
        ctx.dispatch(new RemoveProgressBarStack({ uniqueId: 'LostCard' }));
      }),
      catchError((_err) => {
        ctx.dispatch(new RemoveProgressBarStack({ uniqueId: 'LostCard' }));
        throw _err;
      }),
    );
  }

  @Action(ViewPin, { cancelUncompleted: true })
  viewCardPin(ctx: StateContext<CardManagementStateModel>, action: ViewPin) {
    ctx.dispatch(new AddProgressBarStack({ uniqueId: 'ViewPin' }));
    return this.cardManagementService.viewCardPin(action.payload).pipe(
      map((response) => {
        ctx.dispatch(new ViewPinSuccess(response));
      }),
      tap(() => {
        ctx.dispatch(new RemoveProgressBarStack({ uniqueId: 'ViewPin' }));
      }),
      catchError((_err) => {
        ctx.dispatch(new RemoveProgressBarStack({ uniqueId: 'ViewPin' }));
        throw _err;
      }),
    );
  }

  @Action(MakeVirtualCard, { cancelUncompleted: true })
  createVirtualCard(ctx: StateContext<CardManagementStateModel>, action: MakeVirtualCard) {
    ctx.dispatch(new AddProgressBarStack({ uniqueId: 'MakeVirtualCard' }));
    return this.cardManagementService.createVirtualCard(action.accountId).pipe(
      map(() => {
        ctx.dispatch(new GetCards());
      }),
      tap(() => {
        ctx.dispatch(new RemoveProgressBarStack({ uniqueId: 'MakeVirtualCard' }));
      }),
      catchError((_err) => {
        ctx.dispatch(new RemoveProgressBarStack({ uniqueId: 'MakeVirtualCard' }));
        throw _err;
      }),
    );
  }

  @Action(MakePhysicalCard, { cancelUncompleted: true })
  createPhysicalCard(ctx: StateContext<CardManagementStateModel>, action: MakePhysicalCard) {
    ctx.dispatch(new AddProgressBarStack({ uniqueId: 'MakePhysicalCard' }));
    return this.cardManagementService.createPhysicalCard(action.accountId).pipe(
      map(() => {
        ctx.dispatch(new GetCards());
      }),
      tap(() => {
        ctx.dispatch(new RemoveProgressBarStack({ uniqueId: 'MakePhysicalCard' }));
      }),
      catchError((_err) => {
        ctx.dispatch(new RemoveProgressBarStack({ uniqueId: 'MakePhysicalCard' }));
        throw _err;
      }),
    );
  }

  @Action(DeleteVirtualCardAction, { cancelUncompleted: true })
  deleteVirtualCard(ctx: StateContext<CardManagementStateModel>, action: DeleteVirtualCardAction) {
    ctx.dispatch(new AddProgressBarStack({ uniqueId: 'DeleteVirtualCardAction' }));
    return this.cardManagementService.deleteVirtualCard(action.payload).pipe(
      map(() => {
        ctx.dispatch(new GetCards());
      }),
      tap(() => {
        ctx.dispatch(new RemoveProgressBarStack({ uniqueId: 'DeleteVirtualCardAction' }));
      }),
      catchError((_err) => {
        ctx.dispatch(new RemoveProgressBarStack({ uniqueId: 'DeleteVirtualCardAction' }));
        throw _err;
      }),
    );
  }

  @Action(CancelCardAction, { cancelUncompleted: true })
  cancelCard(ctx: StateContext<CardManagementStateModel>, action: CancelCardAction) {
    ctx.dispatch(new AddProgressBarStack({ uniqueId: 'CancelCardAction' }));
    return this.cardManagementService.cancelCard(action.cardId).pipe(
      tap(() => {
        ctx.dispatch(new RemoveProgressBarStack({ uniqueId: 'CancelCardAction' }));
      }),
      catchError((_err) => {
        ctx.dispatch(new RemoveProgressBarStack({ uniqueId: 'CancelCardAction' }));
        throw _err;
      }),
    );
  }

  @Action(SetCardNameAction, { cancelUncompleted: true })
  setCardName(ctx: StateContext<CardManagementStateModel>, action: SetCardNameAction) {
    ctx.dispatch(new AddProgressBarStack({ uniqueId: 'SetCardNameAction' }));
    return this.cardManagementService.setCardName(action.cardId, action.cardName).pipe(
      tap(() => {
        ctx.dispatch(new RemoveProgressBarStack({ uniqueId: 'SetCardNameAction' }));
      }),
      catchError((_err) => {
        ctx.dispatch(new RemoveProgressBarStack({ uniqueId: 'SetCardNameAction' }));
        throw _err;
      }),
    );
  }

  @Action(GetCardTransactions)
  fetchTransactions(ctx: StateContext<CardManagementStateModel>, action: GetCardTransactions) {
    try {
      if (action.isLoaderShown) {
        ctx.dispatch(new UpdateApiIsLoadingAction(ApiActions.getCardTransactions, true));
      }
      return this.cardManagementService
        .getCardTransaction(action.limit, action.page, action.currentCardId)
        .pipe(
          tap((response) => {
            const state = ctx.getState();
            const txtData = state.cardTransactionDetail ?? {};

            const prevData = txtData.result;
            let transResult;

            if (action.page === 1) {
              transResult = response.result;
            } else {
              transResult = prevData.concat(response.result);
            }
            const res = {
              result: transResult,
              totalPages: response.totalPages,
              limit: action.limit,
              page: response.page,
            };
            ctx.patchState({
              cardTransactionDetail: res,
              isLoading: false,
            });
            ctx.dispatch(new UpdateApiIsLoadingAction(ApiActions.getCardTransactions, false));
          }),
          catchError<unknown, Observable<boolean>>((_err) => {
            ctx.dispatch(new UpdateApiIsLoadingAction(ApiActions.getCardTransactions, false));
            throw _err;
          }),
        );
    } catch (err) {
      ctx.dispatch(new UpdateApiIsLoadingAction(ApiActions.getCardTransactions, false));
      return throwError(() => err);
    }
  }

  @Action(GetCardTransactionDetailById)
  getCardTransactionById(ctx: StateContext<CardManagementStateModel>, action: GetCardTransactionDetailById) {
    return this.cardManagementService.getcCardTransactionDetailById(action.transactionId).pipe(
      tap((response) => {
        ctx.patchState({
          currentCardTransactionDetail: response,
        });
      }),
      catchError((_err) => {
        throw _err;
      }),
    );
  }
}
