import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class IconCacheService {
  private iconCache: Map<string, string> = new Map();

  constructor() {}

  /**
   * Sets an SVG string in the cache for a given icon name.
   * @param iconName The name of the icon.
   * @param svg The SVG string for the icon.
   */
  setIcon(iconName: string, svg: string): void {
    this.iconCache.set(iconName, svg);
  }

  /**
   * Gets the SVG string for a given icon name from the cache.
   * @param iconName The name of the icon.
   * @returns The SVG string if found, otherwise null.
   */
  getIcon(iconName: string): string | null {
    return this.iconCache.get(iconName) || null;
  }

  /**
   * Checks if an icon is present in the cache.
   * @param iconName The name of the icon.
   * @returns True if the icon is in the cache, false otherwise.
   */
  hasIcon(iconName: string): boolean {
    return this.iconCache.has(iconName);
  }

  /**
   * Removes an icon from the cache.
   * @param iconName The name of the icon.
   */
  removeIcon(iconName: string): void {
    this.iconCache.delete(iconName);
  }

  /**
   * Clears the entire icon cache.
   */
  clearCache(): void {
    this.iconCache.clear();
  }
}
