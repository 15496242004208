// generic-input.component.ts
import {
  AfterContentInit,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  TemplateRef,
  effect,
  forwardRef,
} from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ConfigService } from '@app/finxone-web-frontend/app/lib/services/config-service/config-service.service';
import { AutoDestroy } from '@finxone-platform/shared/base-types';
import { InputLabelOptions, TypographyKeys } from '@finxone-platform/shared/sys-config-types';
import { Subject, takeUntil } from 'rxjs';
import { InputValidationFactory, ValidationConfig } from './input-validations-factory';

/**
 * A robust input component which provides enhanced form control capabilities with built-in validation and styling flexibility.
 * Find the usage guide here: https://dev.azure.com/finxone/Finxone%20Platform/_wiki/wikis/Finxone-Platform.wiki/353/Finxone-Input-Component-Usage-guide
 */

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'finxone-input',
  templateUrl: './finxone-input.component.html',
  styleUrls: ['./finxone-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FinxoneInputComponent),
      multi: true,
    },
  ],
})
export class FinxoneInputComponent
  extends InputValidationFactory
  implements ControlValueAccessor, OnInit, OnChanges, AfterContentInit
{
  @AutoDestroy destroy$: Subject<void> = new Subject<void>();
  @ContentChild('prefix', { static: false }) prefixContent: ElementRef;
  @Input() public type = 'text';
  @Input() public placeholder = '';
  @Input() public label = '';
  @Input() public inputMode = 'text';
  @Input() public class = '';
  @Input() public readOnly = false;
  @Input() public isDisabled = false;
  @Input() public formControlInvalid = false;
  @Input() public globalCssVariables: Record<string, string> = {};
  @Input() public customInputTemplate?: TemplateRef<any>;
  @Input() public customErrorTemplate?: TemplateRef<any>;
  @Input() public id = '';
  @Input() public name = '';
  @Input() public value = '';

  // Validation Configuration
  @Input() validationConfig: ValidationConfig[] = [];
  @Input() showErrors = true;

  // Internal form control
  public readonly control = new FormControl();

  private isWorkflow = false;

  // Used for ControlValueAccessor
  // eslint-disable-next-line @typescript-eslint/ban-types
  onChange: Function = () => {};
  // eslint-disable-next-line @typescript-eslint/ban-types
  onTouched: Function = () => {};

  public labelType: InputLabelOptions;
  public labelTypeOptions = InputLabelOptions;
  public textStylingClass: TypographyKeys;
  public isInputOnFocus = false;
  private cssVars: Record<string, string> = {};
  public hasPrefixContent = false;

  constructor(private readonly cd: ChangeDetectorRef, private readonly configService: ConfigService) {
    super();
    effect(() => {
      const globalCssVariables = this.configService.globalCssVariables();
      if (globalCssVariables) {
        this.cssVars = globalCssVariables;
        //get the label type and text style class from root styles
        this.labelType = this.getCssVariableValue('--inputFieldLabelType') as InputLabelOptions;
        this.textStylingClass = this.getCssVariableValue('--inputFieldTextStylingClass') as TypographyKeys;
      }
    });
  }

  ngOnInit() {
    this.control.statusChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.cd.markForCheck();
    });
  }

  ngAfterContentInit() {
    // Check if the prefix content exists
    if (this.prefixContent) {
      this.hasPrefixContent = true;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['value']?.currentValue !== undefined) {
      this.control.setValue(changes['value']?.currentValue);
    }
    if (changes['validationConfig']?.currentValue !== undefined) {
      const validators = this.setupValidation(this.validationConfig);
      this.control.setValidators(validators);
      this.control.updateValueAndValidity();
    }
    if (changes['formControlInvalid']?.currentValue !== undefined) {
      this.setFormControlValidity(this.formControlInvalid);
    }
  }

  private setFormControlValidity(formControlInvalid: boolean) {
    if (!formControlInvalid) {
      this.control.markAsPristine();
      this.control.markAsUntouched();
    } else {
      this.control.markAsDirty();
      this.control.markAsTouched();
    }
  }

  public writeValue(value: string): void {
    this.value = value;
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
    isDisabled ? this.control.disable() : this.control.enable();
  }

  public onInputChange(event: Event) {
    const target = event.target as HTMLInputElement;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    this.onChange(value);
    this.cd.markForCheck();
  }

  private getCssVariableValue(variableName: string): string {
    return this.cssVars[variableName];
  }

  public getErrorMessageForField(validator: ValidationConfig): string {
    return this.getErrorMessage(validator);
  }
}
