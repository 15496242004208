import { Injectable } from '@angular/core';
import { UsersList } from '@finxone-platform/shared/sys-config-types';
import { Action, Selector, State, StateContext, createSelector } from '@ngxs/store';

export interface ApiLoadingModel {
  getAccounts: {
    isLoading: boolean;
  };
  getTransactions: {
    isLoading: boolean;
  };
  getCardTransactions: {
    isLoading: boolean;
  };
  getBeneficiaries: {
    isLoading: boolean;
  };
  getRevenirTripTransactions: {
    isLoading: boolean;
  };
  getBeneficiaryRequiredFields: {
    isLoading: boolean;
  };
  getRevenirTripList: {
    isLoading: boolean;
  };
  getRevenirTransactionDetails: {
    isLoading: boolean;
  };
  getRevenirTransactionItems: {
    isLoading: boolean;
  };
  getCardsKycApproval: {
    isLoading: boolean;
  };
  getRevenirTransactionReceipt: {
    isLoading: boolean;
  };
  getRevenirArchivedTrips: {
    isLoading: boolean;
  };
  makeTripAsArchiveTrip: {
    isLoading: boolean;
  };
  getRevenirBarcodeImage: {
    isLoading: boolean;
  };
  fetchingClientDetails: {
    isLoading: boolean;
  };
  UploadBoardingPass: {
    isLoading: boolean;
  };
  UploadRevenirTransactionReceipt: {
    isLoading: boolean;
  };
  reviewBoardingPass: {
    isLoading: boolean;
  };
  uploadBoardingPassWithId: {
    isLoading: boolean;
  };
  getRevenirTripDetails: {
    isLoading: boolean;
  };
  uploadPassport: {
    isLoading: boolean;
  };
  revenirKycStatus: {
    isLoading: boolean;
  };
  confirmAndArchiveTrip: {
    isLoading: boolean;
  };
}

export enum ApiActions {
  'getAccounts' = 'getAccounts',
  'getTransactions' = 'getTransactions',
  'getCardTransactions' = 'getCardTransactions',
  'getBeneficiaries' = 'getBeneficiaries',
  'getRevenirTripTransactions' = 'getRevenirTripTransactions',
  'getBeneficiaryRequiredFields' = 'getBeneficiaryRequiredFields',
  'getRevenirTripList' = 'getRevenirTripList',
  'getRevenirTransactionDetails' = 'getRevenirTransactionDetails',
  'getRevenirTransactionItems' = 'getRevenirTransactionItems',
  'getRevenirTransactionReceipt' = 'getRevenirTransactionReceipt',
  'getRevenirArchivedTrips' = 'getRevenirArchivedTrips',
  'makeTripAsArchiveTrip' = 'makeTripAsArchiveTrip',
  'confirmAndArchiveTrip' = 'confirmAndArchiveTrip',
  'getRevenirBarcodeImage' = 'getRevenirBarcodeImage',
  'getCardsKycApproval' = 'getCardsKycApproval',
  'fetchingClientDetails' = 'fetchingClientDetails',
  'UploadBoardingPass' = 'UploadBoardingPass',
  'UploadRevenirTransactionReceipt' = 'UploadRevenirTransactionReceipt',
  'reviewBoardingPass' = 'reviewBoardingPass',
  'uploadBoardingPassWithId' = 'uploadBoardingPassWithId',
  'getRevenirTripDetails' = 'getRevenirTripDetails',
  'uploadPassport' = 'uploadPassport',
  'revenirKycStatus' = 'revenirKycStatus',
}

export class UpdateApiIsLoadingAction {
  static readonly type = '[ApiLoadingState] UpdateApiAction';
  constructor(public apiAction: ApiActions, public isLoading: boolean) {}
}

@State<ApiLoadingModel>({
  name: 'apiLoading',
  defaults: {
    getAccounts: {
      isLoading: false,
    },
    getTransactions: {
      isLoading: false,
    },
    getCardTransactions: {
      isLoading: false,
    },
    getBeneficiaries: {
      isLoading: false,
    },
    getRevenirTripTransactions: {
      isLoading: false,
    },
    getBeneficiaryRequiredFields: {
      isLoading: false,
    },
    getRevenirTripList: {
      isLoading: false,
    },
    getRevenirTransactionDetails: {
      isLoading: false,
    },
    getRevenirTransactionItems: {
      isLoading: false,
    },
    getRevenirTransactionReceipt: {
      isLoading: false,
    },
    getRevenirArchivedTrips: {
      isLoading: false,
    },
    makeTripAsArchiveTrip: {
      isLoading: false,
    },
    getRevenirBarcodeImage: {
      isLoading: false,
    },
    getCardsKycApproval: {
      isLoading: false,
    },
    fetchingClientDetails: {
      isLoading: false,
    },
    UploadBoardingPass: {
      isLoading: false,
    },
    UploadRevenirTransactionReceipt: {
      isLoading: false,
    },
    reviewBoardingPass: {
      isLoading: false,
    },
    uploadBoardingPassWithId: {
      isLoading: false,
    },
    getRevenirTripDetails: {
      isLoading: false,
    },
    uploadPassport: {
      isLoading: false,
    },
    revenirKycStatus: {
      isLoading: false,
    },
    confirmAndArchiveTrip: {
      isLoading: false,
    },
  },
})
@Injectable()
export class ApiLoadingState {
  static getApiState(apiCall: ApiActions) {
    return createSelector([ApiLoadingState], (state: ApiLoadingModel) => {
      return state[apiCall];
    });
  }

  @Action(UpdateApiIsLoadingAction)
  fetchUsers(ctx: StateContext<UsersList>, action: UpdateApiIsLoadingAction) {
    ctx.patchState({
      [action.apiAction]: {
        isLoading: action.isLoading,
      },
    });
  }

  @Selector([ApiLoadingState])
  static getApiStateAsLoader(state: ApiLoadingModel): boolean {
    const loadingStatesAsLoader = [
      'getCardsKycApproval',
      // Add more Api actions here to show the loading spinner with overlay on the mobile-container
    ] as ApiActions[];

    return loadingStatesAsLoader.some((apiAction) => state[apiAction].isLoading);
  }
}
