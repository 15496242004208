import { ValidatorFn, Validators } from '@angular/forms';

export interface ValidationConfig {
  type: string;
  message: string;
  validatorFn?: ValidatorFn;
  params?: any;
}

export abstract class InputValidationFactory {
  /**
   * Generates an error message based on the validation configuration
   * @param validator ValidationConfig object containing error details
   * @returns Formatted error message string
   */
  protected getErrorMessage(validator: ValidationConfig): string {
    if (validator.message) {
      // Handle dynamic parameter interpolation
      let message = validator.message;
      if (validator.params) {
        Object.keys(validator.params).forEach((key) => {
          message = message.replace(`{${key}}`, validator.params[key]);
        });
      }
      return message;
    }

    // Default messages
    switch (validator.type) {
      case 'required':
        return 'This field is required';
      case 'minlength':
        return `Minimum length is ${validator.params} characters`;
      case 'maxlength':
        return `Maximum length is ${validator.params} characters`;
      case 'email':
        return 'Please enter a valid email address';
      case 'pattern':
        return 'Invalid format';
      case 'min':
        return `Minimum value is ${validator.params}`;
      case 'max':
        return `Maximum value is ${validator.params}`;
      default:
        return 'Invalid value';
    }
  }

  /**
   * Sets up validators for a form control based on validation configuration
   * @param control FormControl to apply validators to
   * @param validationConfig Array of validation configurations
   */
  protected setupValidation(validationConfig: ValidationConfig[]): ValidatorFn[] {
    if (validationConfig.length > 0) {
      const validators: ValidatorFn[] = validationConfig
        .map((config) => {
          if (config.validatorFn) {
            return config.validatorFn;
          }

          switch (config.type) {
            case 'required':
              return Validators.required;
            case 'minlength':
              return Validators.minLength(config.params);
            case 'maxlength':
              return Validators.maxLength(config.params);
            case 'pattern': {
              const pattern = typeof config.params === 'string' ? new RegExp(config.params) : config.params;
              return Validators.pattern(pattern);
            }
            case 'email':
              return Validators.email;
            case 'min':
              return Validators.min(config.params);
            case 'max':
              return Validators.max(config.params);
            default:
              return null;
          }
        })
        .filter((validator) => validator !== null) as ValidatorFn[];
      return validators;
    }
    return [];
  }
}
