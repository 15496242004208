import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'finxone-web-frontend-pin-pad',
  templateUrl: './pin-pad.component.html',
  styleUrl: './pin-pad.component.scss',
})
export class PinPadComponent {
  @Input() public pin = '';
  @Input() public error = '';
  @Input() public maxLength = 6;
  @Input() public loginEnterPinSubTitle = 'Enter Passcode';
  @Output() pinPad = new EventEmitter();
  @Output() pinChange = new EventEmitter<string>();
  public pinPadMobileLayout: (string | number)[][];
  private flutterWebView = (window as any).flutter_inappwebview;

  constructor() {
    this.pinPadMobileLayout = [
      [1, 2, 3],
      [4, 5, 6],
      [7, 8, 9],
      [this.isBiometricEnabled ? 'biometric' : 'empty', 0, 'clear'],
    ];
  }

  get pinPadElement() {
    return this.pinPadMobileLayout.flat();
  }

  private async onBiometric(): Promise<void> {
    if (this.flutterWebView) {
      this.flutterWebView.callHandler('biometricsLogin', []);
    }
  }

  public handleMobilePinPad(value: number | string): void {
    if (value === 'biometric') {
      this.onBiometric().then();
      return;
    }
    if (typeof value === 'number' && this.pin.length < this.maxLength) {
      this.pin += value.toString();
      this.pinChange.emit(this.pin);
      this.error = '';
      this.pinPad.emit();
    } else if (value === 'clear') {
      this.pin = this.pin.slice(0, -1);
      this.pinChange.emit(this.pin);
      this.error = '';
    }
  }

  private get isBiometricEnabled(): boolean {
    const preferences: string | null = localStorage.getItem('APP_PREFERENCES');
    if (preferences) {
      const { isBiometricEnabled } = JSON.parse(preferences);
      return !!isBiometricEnabled;
    }
    return false;
  }

  public isNumber(value: number | string): boolean {
    return typeof value === 'number';
  }

  public isAction(value: number | string): boolean {
    return typeof value === 'string' && value !== 'empty' && value !== 'biometric';
  }

  public isBiometric(value: number | string): boolean {
    return value === 'biometric';
  }
}
