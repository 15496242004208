import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  CardInfoType,
  CardLimitsType,
  CardTransactionDetail,
  CardTransactionInfoType,
  ChangePinRequest,
  CustomFreezeResponse,
  PaginatedResponse,
  ReplaceCardRequest,
  SORT_SLUG,
  SecureCardInfoResponse,
  SecureCardTokenInfo,
} from '@finxone-platform/shared/sys-config-types';
import { Store } from '@ngxs/store';
import { Observable, Subject, catchError, map, tap, throwError } from 'rxjs';
import { ConfigService } from '../config-service/config-service.service';

@Injectable({ providedIn: 'root' })
export class CardManagementService {
  private baseUrl = '';
  public otpCode = '';
  public originUrl = '';
  public sendDataToBottomPopup: Subject<{
    popupIndex: number;
    isFullPagePopup: boolean;
  }> = new Subject();
  private _cvvDetails: SecureCardInfoResponse;

  constructor(private http: HttpClient, private configService: ConfigService, private store: Store) {
    this.configService.getApi('account_service').subscribe((response) => {
      this.baseUrl = response;
    });
  }

  public get cvvDetails(): SecureCardInfoResponse {
    return this._cvvDetails;
  }

  getCards(
    page = 1,
    size = 20,
    sortOrder = SORT_SLUG.DESC,
    sortKey = 'createdDate',
  ): Observable<PaginatedResponse<CardInfoType>> {
    return this.http
      .get<PaginatedResponse<CardInfoType>>(
        `${this.baseUrl}/cards?page=${page}&size=${size}&sort=${sortOrder}&sortKey=${sortKey}`,
      )
      .pipe(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        catchError<any, Observable<boolean>>((_err) => {
          console.error(`Error getting account cards: ${JSON.stringify(_err)}`);
          throw _err;
        }),
      );
  }

  getCardManagementKyc(): Observable<boolean> {
    return this.http.get<boolean>(`${this.baseUrl}/cards/check-approval-status`).pipe(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      catchError<any, Observable<boolean>>((_err) => {
        console.error(`Error getting card management kyc: ${JSON.stringify(_err)}`);
        throw _err;
      }),
    );
  }

  freezeCard(request: { accountId?: string; cardId: string }) {
    return this.http.post<CustomFreezeResponse>(this.baseUrl + '/cards/freeze', request).pipe(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      catchError((error: any): never => {
        console.error(`Error trying freezeCard: ${JSON.stringify(error)}`);
        throw error;
      }),
    );
  }

  unfreezeCard(request: { accountId?: string; cardId: string }) {
    return this.http.post<CustomFreezeResponse>(this.baseUrl + '/cards/unfreeze', request).pipe(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      catchError((error: any): never => {
        console.error(`Error trying unfreezeCard: ${JSON.stringify(error)}`);
        throw error;
      }),
    );
  }

  replaceCard(payload: ReplaceCardRequest) {
    return this.http.post<CardInfoType>(this.baseUrl + '/cards/replace-card', payload).pipe(
      catchError((_err) => {
        console.error(`Error fetching replaceCard: ${JSON.stringify(_err)}`);
        throw _err;
      }),
    );
  }

  getCardSecureToken(payload: {
    publicKey: string;
    tokenPurpose: string;
    accountId: string;
    cardId: string;
  }) {
    return this.http.post<SecureCardTokenInfo>(this.baseUrl + '/cards/secure-token', payload).pipe(
      catchError<SecureCardTokenInfo, Observable<SecureCardTokenInfo>>((_err) => {
        console.error(`Error fetching getCardSecureToken: ${JSON.stringify(_err)}`);
        throw _err;
      }),
    );
  }

  changePin(payload: ChangePinRequest) {
    return this.http.post<boolean>(this.baseUrl + '/cards/change-pin', payload).pipe(
      catchError<boolean, Observable<boolean>>((_err) => {
        console.error(`Error fetching changePin: ${JSON.stringify(_err)}`);
        throw _err;
      }),
    );
  }

  activateCard(payload: { accountId: string; cardId: string }) {
    return this.http.get<boolean>(this.baseUrl + '/cards/activate', { params: payload }).pipe(
      catchError<boolean, Observable<boolean>>((_err) => {
        console.error(`Error fetching activateCard: ${JSON.stringify(_err)}`);
        throw _err;
      }),
    );
  }

  getCVVDetails(payload: { accountId?: string; cardId: string; secureToken?: string }) {
    return this.http.post<SecureCardInfoResponse>(this.baseUrl + '/cards/cvv-details', payload).pipe(
      tap((response: SecureCardInfoResponse) => {
        this._cvvDetails = response;
      }),
      catchError((_err) => {
        console.error(`Error fetching getCVVDetails: ${JSON.stringify(_err)}`);
        throw _err;
      }),
    );
  }

  viewCardPin(payload: { accountId: string; cardId: string; secureToken: string }) {
    return this.http.post<SecureCardInfoResponse>(this.baseUrl + '/cards/view-pin', payload).pipe(
      catchError((_err) => {
        console.error(`Error fetching viewCardPin: ${JSON.stringify(_err)}`);
        throw _err;
      }),
    );
  }

  getCardDetails(payload: { accountId: string; cardId: string }) {
    return this.http
      .get<CardInfoType>(this.baseUrl + '/cards/details', {
        params: payload,
      })
      .pipe(
        catchError<CardInfoType, Observable<CardInfoType>>((_err) => {
          console.error(`Error fetching getCardDetails: ${JSON.stringify(_err)}`);
          throw _err;
        }),
      );
  }

  public getCardLimits(cardId: string) {
    return this.http.get<CardLimitsType[]>(this.baseUrl + `/cards/${cardId}/limits`).pipe(
      catchError<CardLimitsType[], Observable<CardLimitsType[]>>((_err) => {
        console.error(`Error fetching getCardLimits: ${JSON.stringify(_err)}`);
        throw _err;
      }),
    );
  }

  createVirtualCard(accountId: string): Observable<string> {
    const request = {
      accountId: [accountId],
      cardType: 'virtual',
    };
    return this.http.post(this.baseUrl + '/cards', request).pipe(
      map(() => accountId),
      catchError((_err) => {
        console.error(`Error fetching createVirtualCard: ${JSON.stringify(_err)}`);
        throw _err;
      }),
    );
  }

  createPhysicalCard(accountId: string): Observable<string> {
    const request = {
      accountId: [accountId],
      cardType: 'physical',
    };
    return this.http.post(this.baseUrl + '/cards', request).pipe(
      map(() => accountId),
      catchError((_err) => {
        console.error(`Error fetching createPhysicalCard: ${JSON.stringify(_err)}`);
        throw _err;
      }),
    );
  }

  deleteVirtualCard(payload: { cardId: string; accountId: string }): Observable<string> {
    return this.http.delete(`${this.baseUrl}/cards/${payload.cardId}`).pipe(
      map(() => payload.accountId),
      catchError((_err) => {
        console.error(`Error fetching deleteVirtualCard: ${JSON.stringify(_err)}`);
        throw _err;
      }),
    );
  }

  cancelCard(cardId: string): Observable<string> {
    return this.http.delete(`${this.baseUrl}/cards/${cardId}`).pipe(
      map(() => cardId),
      catchError((_err) => {
        console.error(`Error fetching cancelCard: ${JSON.stringify(_err)}`);
        throw _err;
      }),
    );
  }

  setCardName(cardId: string, cardName: string): Observable<string> {
    const url = `${this.baseUrl}/cards/${cardId}/name`;
    const requestPayload = { nickname: cardName };
    return this.http.patch(url, requestPayload).pipe(
      map(() => cardId),
      catchError((error) => {
        console.error(`Error in setCardName:`, error);
        return throwError(() => error);
      }),
    );
  }

  public getcCardTransactionDetailById(transactionId: string) {
    return this.http.get<CardTransactionDetail>(`${this.baseUrl}/cards/transactions/${transactionId}`).pipe(
      catchError((error) => {
        console.error(`Error getting card transaction detail:`, error);
        return throwError(() => error);
      }),
    );
  }

  viewEPIN(cardId: string, accessToken: string) {
    const headers = new HttpHeaders({
      confirmation: accessToken,
    });
    return this.http.get<{ epin: string }>(`${this.baseUrl}/cards/${cardId}/epin`, { headers }).pipe(
      map((ePinResult) => ePinResult?.epin ?? ''),
      catchError((error) => {
        console.error(`Error fetching viewEPIN: ${JSON.stringify(error)}`);
        return throwError(() => error);
      }),
    );
  }

  public getCardTransaction(
    limit: number,
    page: number,
    cardId: string,
  ): Observable<PaginatedResponse<CardTransactionInfoType>> {
    return this.http
      .get<PaginatedResponse<CardTransactionInfoType>>(
        `${this.baseUrl}/cards/${cardId}/transactions?page=${page}&size=${limit}`,
      )
      .pipe(
        catchError<any, Observable<boolean>>((_err) => {
          console.error(`Error getting account cards: ${JSON.stringify(_err)}`);
          throw _err;
        }),
      );
  }
}
