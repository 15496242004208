import { CardInfoType, SORT_SLUG } from '@finxone-platform/shared/sys-config-types';

export class GetCards {
  static readonly type = '[Account] GetCards';
  constructor(
    public page = 1,
    public limit = 5,
    public sortOrder: SORT_SLUG = SORT_SLUG.DESC,
    public sortKey = 'createdDate',
  ) {}
}

export class GetCardDetails {
  static readonly type = '[Card] GetCardDetails';
  constructor(public payload: { accountId: string; cardId: string }) {}
}

export class GetCardLimits {
  static readonly type = '[Card] GetCardLimits';
  constructor(public cardId: string) {}
}

export class UpdateCurrentCardDetail {
  static readonly type = '[Card] UpdateCurrentCardDetail';
  constructor(public cardItemInfo: CardInfoType, public cardId?: string) {}
}

export class FlipCard {
  static readonly type = '[Card] FlipCard';
}

export class FreezeCurrentCard {
  static readonly type = '[Card] Freeze Current Card';
}

export class UnFreezeCurrentCard {
  static readonly type = '[Card] Unfreeze Current Card';
}
