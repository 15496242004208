import { HttpClient } from '@angular/common/http';
import { Injectable, signal } from '@angular/core';

import {
  APP_ZONES,
  AlertStyleConfigurationType,
  BaseCssConfig,
  BaseCssProperties,
  BaseWidgetProperties,
  DeviceData,
  FileUploadResponse,
  IFrameAdditionalConfig,
  IFrameAdditionalConfigStyle,
  LineAdditionalConfigStyle,
  NavBarDesktop,
  NavBarMobile,
  PaymentProvider,
  State,
  SupportedDevice,
  SystemConfiguration,
  SystemRoles,
  SystemZoneDetail,
  TTLMap,
  TextAlignToJustifyContent,
  ThemeConfig,
  UiPageConfig,
  UiSystemConfig,
  UiZoneWidgetAttributeConfig,
  UiZoneWidgetConfig,
  WidgetNames,
  WorkflowPageStateConfig,
  ZoneConfiguration,
  baseCssConfigStyle,
  customDataQuestionType,
  defaultCssProperties,
  defaultGridPosition,
  defaultTheme,
  getPrimaryTheme,
} from '@finxone-platform/shared/sys-config-types';
import * as Sentry from '@sentry/angular-ivy';
import { plainToClass } from 'class-transformer';
import jwt_decode, { InvalidTokenError } from 'jwt-decode';
import {
  BehaviorSubject,
  Observable,
  ReplaySubject,
  Subject,
  catchError,
  combineLatest,
  distinctUntilChanged,
  filter,
  first,
  firstValueFrom,
  map,
  of,
  switchMap,
  take,
  tap,
  throwError,
} from 'rxjs';
import { Country } from '../../dtos/countries.dto';
import { isAuthenticatedRoute } from '../../utils/auth-checks.utils';
import { AuthConfig, Config } from './config.type';

import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { CountriesCurrenciesService } from '@app/finxone-web-frontend/app/lib/services/countries-currencies-service/countries-currencies.service';
import { Locale } from '@finxone-platform/shared/enums';
import { AlertDetails, AlertResponse } from '@finxone-platform/shared/sys-config-types';
import { Select, Store } from '@ngxs/store';
import isSvg from 'is-svg';
import { DeviceDetectorService } from 'ngx-device-detector';
import { LocaleVariableStateModel } from '../../../lib/state/locale-variable.state';
import { UpdatePaymentGatewayConfig } from '../../actions/account.action';
import { UpdateAppData } from '../../actions/app-data.action';
import { SetLocaleVariableContent } from '../../actions/locale-variable.action';
import { UpdateProjectAlertsSubject } from '../../actions/project-alerts.action';
import { UpdateProjectSettings } from '../../actions/project-settings.action';
import { SetRole, UpdateDevice } from '../../actions/role.action';
import { Currency } from '../../dtos/currencies.dto';
import { RoleState, RoleStateModel } from '../../state/role.state';
import { ProfileState } from '../../state/user-profile.state';
import { logLoadEventToFlutter } from '../../utils/flutter.utils';
import { loadStyleSheet } from '../../utils/themeing.utils';
import { removeSpaceFromString } from '../../utils/utils';
import { formatNameAsUrl } from '../../utils/zone-url.utils';
import { DeviceTypeService } from '../device-type/device-type.service';
import { StateService } from '../state-service/state.service';

export interface IConfigService {
  refreshCountries(): void;
  refreshSystemConfig(): void;
  refreshConfig(): void;
  getApi(key: string): Observable<string>;
  getApiConfig(): Observable<Map<string, string>>;
  getCountries(): Observable<Country[]>;
  getCurrencies(): Observable<Currency[]>;
  getProjectPaymentProvider(): Observable<PaymentProvider>;
  getCardManagementWidgets(roleInfo: RoleStateModel): Observable<UiPageConfig>;
  getCardManagementZoneWidgetsLayout(roleInfo: RoleStateModel): Observable<UiPageConfig>;
  getPrivacyPageWidgetLayout(): Observable<UiPageConfig>;
  getTheme(): Observable<ThemeConfig>;
  getSystemName(): Observable<string>;
  getLegalEntityName(): Observable<string>;
  getAuthConfig(): Observable<AuthConfig>;
  getConfig(): Observable<Config>;
  getProfileZone(role: string, subscription: string, device: string): Observable<UiPageConfig>;

  getCurrentRoleZoneWidgetsConfig(zoneOverride?: string, pageIndexOveride?: number): Observable<UiPageConfig>;
  fetchCurrentZoneConfiguration(): Observable<ZoneConfiguration>;
  getMobileNavBarItems(): Observable<NavBarMobile>;
  getWidgetsConfig(): Observable<UiSystemConfig>;
  getZoneWithNoRoleContext(): Observable<SystemZoneDetail | undefined>;
  getRoles(): Observable<SystemRoles>;
  getSupportedCurrencies(): Observable<string[]>;
}
@Injectable({
  providedIn: 'root',
})
export class ConfigService implements IConfigService {
  private _config$ = new ReplaySubject<Config>(1);
  public config: Config;
  private _countries$ = new ReplaySubject<Country[]>(1);
  private _systemConfiguration$ = new ReplaySubject<SystemConfiguration>(1);
  private _systemAlertsConfig$ = new ReplaySubject<AlertResponse>(1);
  kycRegulated: boolean;

  zoneUrls: {
    zoneUrl: string;
    pageUrl: string;
  } = { zoneUrl: '', pageUrl: '' };

  @Select(RoleState.getRole)
  roles!: Observable<RoleStateModel>;
  // DO NOT modify roleInfo directly as it modifies the value in memory for ngxs silectly
  // do to pass by reference
  public roleInfo: RoleStateModel;

  private activeOrgId$ = this.store.select(ProfileState.getProfile).pipe(
    map((profile) => profile.activeOrganisationId),
    filter((activeOrgId) => !!activeOrgId), // Ensure activeOrgId is populated
  );

  private newRoleSet: Observable<RoleStateModel> = this.roles.pipe(
    filter((roles) => !!roles.role && !!roles.device), // Ensure roles.role is set
  );

  projectPaymentProviderObs = new Subject<PaymentProvider>();
  private dateRangeSource = new BehaviorSubject<{
    startDate: string | null;
    endDate: string | null;
  }>({ startDate: '', endDate: '' });
  dateRange$ = this.dateRangeSource.asObservable();
  private supportedCurrencies: string[];
  public globalCssString = signal<string>('');
  public globalCssVariables = signal<Record<string, string>>({});

  constructor(
    private http: HttpClient,
    private store: Store,
    private deviceTypeService: DeviceTypeService,
    private deviceDetectorService: DeviceDetectorService,
    private router: Router,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private countriesCurrenciesService: CountriesCurrenciesService,
    private stateService: StateService,
  ) {
    this.roles.subscribe((role) => {
      this.roleInfo = role;
    });

    Sentry.addBreadcrumb({
      category: 'Init',
      message: 'Config service initialised',
      level: 'info',
    });
    this.refreshConfig();
    this.refreshCountries();
    this.refreshSystemConfig();
    this.refreshProjectConfig();
  }

  private setupRoleTheme(role: string) {
    this.getRoles()
      .pipe(take(1))
      .subscribe((roleConfigs) => {
        const alternativeTheme = getPrimaryTheme(roleConfigs[role]?.alternativeThemes);
        if (alternativeTheme && localStorage.getItem('configuredTheme') !== alternativeTheme) {
          localStorage.setItem('configuredTheme', alternativeTheme);
          this.refreshSystemConfig();
        }
      });
  }

  public getRoles(): Observable<SystemRoles> {
    return this._systemConfiguration$.asObservable().pipe(
      map((config) => {
        return config.roles;
      }),
    );
  }

  public getAlertSettingsAndStyleConfig(): Observable<AlertStyleConfigurationType | undefined> {
    return this._systemConfiguration$.asObservable().pipe(
      map((config) => {
        return config.ui.alertsConfig;
      }),
    );
  }

  private collectRouteParams() {
    let params: any = {};
    const stack: ActivatedRouteSnapshot[] = [];

    if (this.router?.routerState?.snapshot?.root) {
      stack.push(this.router.routerState.snapshot.root);
    }

    while (stack.length > 0) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const route = stack.pop()!;
      params = { ...params, ...route?.params };
      stack.push(...route.children);
    }

    this.zoneUrls.zoneUrl = params['zone'] ?? undefined;
    this.zoneUrls.pageUrl = params['page'] ?? undefined;

    /*****************************************************************************
      DO NOT TOUCH the below without extreme caution

      collectRouteParams runs on most calls in this service
      it has the power below to modify roles (this shouldn't be done here)

      It can very easily cause an infinite loop where something listen to changes in roles
      and then gets some config but the act of getting the config causes this to run
      then modifies the role and loops non-stop
    ******************************************************************************/
    if (
      this.zoneUrls.zoneUrl != undefined &&
      (this.zoneUrls.zoneUrl.includes('onboarding') || this.zoneUrls.zoneUrl.includes('verification'))
    ) {
      if (this.roleInfo?.role === '' || (params['role'] && this.roleInfo?.role !== params['role'])) {
        this.store.dispatch(new SetRole(params['role'] ?? ''));
      }
    } else {
      const activeRole = this.store.selectSnapshot(ProfileState.getProfile)?.activeRole;
      if (
        this.roleInfo?.role &&
        this.roleInfo?.role !== activeRole &&
        isAuthenticatedRoute(window.location.pathname)
      ) {
        this.store.dispatch(new SetRole(activeRole ?? this.roleInfo.role));
      }
    }
    /**************************************************************************
      DO NOT TOUCH the above without extreme caution
    ***************************************************************************/
  }

  private getSystemConfigAndZone(): Observable<[SystemConfiguration, { zone: string; pageIndex: number }]> {
    return combineLatest([this._systemConfiguration$, this.getZoneByRoute()]);
  }

  private getSystemAlertsConfig(): Observable<AlertResponse> {
    return this._systemAlertsConfig$.asObservable();
  }

  public getSystemAlerts(): Observable<{
    [errorCode: string]: AlertDetails;
  }> {
    return this.getSystemAlertsConfig().pipe(
      map((alertsConfig) => {
        return alertsConfig.value;
      }),
    );
  }

  getSVGFileFromURL(svgUrl: string) {
    return this.http.get(svgUrl, {
      responseType: 'text',
    });
  }

  setDateRange(startDate: string | null, endDate: string | null) {
    this.dateRangeSource.next({ startDate, endDate });
  }

  refreshCountries() {
    this.http
      .get<Country[]>('./assets/JSON/countries.json')
      .pipe(
        tap((countries) => {
          this._countries$.next(countries);
        }),
        catchError((err) => {
          console.error(err);
          return throwError(() => err);
        }),
      )
      .subscribe();
  }

  refreshSystemConfig() {
    this.getApi('metadata_service')
      .pipe(
        switchMap((baseUrl) => {
          return this.http
            .get<{ updatedAt?: string }>(baseUrl + '/system-config/updated', {
              params: { configKey: 'system' },
            })
            .pipe(
              tap((res) => {
                let params: {
                  configKey: string;
                  updatedAt?: string;
                } = {
                  configKey: 'system',
                };
                if (res.updatedAt)
                  params = {
                    configKey: 'system',
                    updatedAt: res.updatedAt,
                  };
                this.getNewSystemConfig(params);
              }),
              catchError((err) => {
                console.error(err);
                return throwError(() => err);
              }),
            );
        }),
      )
      .subscribe();
  }

  private getNewSystemConfig(params: { configKey: string; updatedAt?: string }) {
    this.getApi('metadata_service')
      .pipe(
        switchMap((baseUrl) => {
          return this.http
            .get(baseUrl + '/system-config', {
              params: params,
            })
            .pipe(
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              tap((res: any) => {
                Sentry.addBreadcrumb({
                  category: 'Init',
                  message: 'System Config fetched',
                  level: 'info',
                });
                const systemConfig = res[0]['config_value'] as SystemConfiguration;
                this._systemConfiguration$.next(systemConfig);

                this.setSystemName(systemConfig);

                this.setLogo(systemConfig);

                this.setStyleSheet(systemConfig);

                logLoadEventToFlutter('config-loaded');
                Sentry.addBreadcrumb({
                  category: 'Init',
                  message: 'Logged config loaded flutter event',
                  level: 'info',
                });
                localStorage.setItem('ui_system_config', JSON.stringify(systemConfig.ui.system));
              }),
              catchError((err) => {
                console.error(err);
                return throwError(() => err);
              }),
            );
        }),
      )
      .subscribe();
  }

  public getLocaleVariableContent() {
    this.getApi('metadata_service')
      .pipe(
        switchMap((baseUrl) => {
          return this.http.get<LocaleVariableStateModel>(baseUrl + '/locale-variable').pipe(
            tap((res) => {
              this.store.dispatch(new SetLocaleVariableContent(res.content));
            }),
            catchError((err) => {
              return throwError(() => err);
            }),
          );
        }),
      )
      .subscribe();
  }

  refreshAlerts(locale: Locale) {
    this.getApi('metadata_service')
      .pipe(
        switchMap((baseUrl) => {
          return this.http
            .get<{ updatedAt?: string }>(baseUrl + '/alerts/updated', {
              params: { key: 'alerts', locale: locale },
            })
            .pipe(
              tap((res) => {
                let params: {
                  key: string;
                  locale: Locale;
                  updatedAt?: string;
                } = {
                  key: 'alerts',
                  locale: locale,
                };

                if (res.updatedAt)
                  params = {
                    key: 'alerts',
                    locale: locale,
                    updatedAt: res.updatedAt,
                  };
                this.getNewAlertsConfig(params);
              }),
              catchError((err) => {
                console.error(err);
                return throwError(() => err);
              }),
            );
        }),
      )
      .subscribe();
  }

  private getNewAlertsConfig(params: { key: string; locale: Locale; updatedAt?: string }) {
    this.getApi('metadata_service')
      .pipe(
        switchMap((baseUrl) => {
          return this.http
            .get<AlertResponse>(baseUrl + '/alerts', {
              params: params,
            })
            .pipe(
              tap((res) => {
                this.store.dispatch(new UpdateProjectAlertsSubject(res));
              }),
              catchError((err) => {
                console.error(err);
                return throwError(() => err);
              }),
            );
        }),
      )
      .subscribe();
  }

  private setSystemName(systemConfig: SystemConfiguration) {
    const configuredTheme = localStorage.getItem('configuredTheme');
    let systemName = systemConfig.ui.system.systemName;
    let legalEntityName = systemConfig.ui.system.legalEntityName;
    if (
      configuredTheme &&
      systemConfig.ui.system.alternativeThemes &&
      systemConfig.ui.system.alternativeThemes[configuredTheme]
    ) {
      systemName = systemConfig.ui.system.alternativeThemes[configuredTheme].systemName ?? systemName;
      legalEntityName =
        systemConfig.ui.system.alternativeThemes[configuredTheme].legalEntityName ?? legalEntityName;
    }
    this.store.dispatch(new UpdateAppData(systemName, legalEntityName ?? systemName));
    // Set page title based on system name
    const title = document.getElementById('pageTitle') as HTMLElement;
    if (title) title.innerHTML = systemName;
  }

  private setLogo(systemConfig: SystemConfiguration) {
    const configuredTheme = localStorage.getItem('configuredTheme');
    let logo = systemConfig.ui.system.theme.logo;
    if (
      configuredTheme &&
      systemConfig.ui.system.alternativeThemes &&
      systemConfig.ui.system.alternativeThemes[configuredTheme]
    ) {
      logo = systemConfig.ui.system.alternativeThemes[configuredTheme].theme.logo ?? logo;
    }
    let logoSvg = '<svg xmlns="http://www.w3.org/2000/svg"></svg>';
    try {
      logoSvg = window.atob(logo);
    } catch (error) {
      console.warn('No valid svg logo set, setting empty');
    }

    if (isSvg(logoSvg)) {
      this.matIconRegistry.addSvgIconLiteral(
        'customer-logo',
        this.domSanitizer.bypassSecurityTrustHtml(logoSvg),
      );
      document
        ?.getElementById('appFavicon')
        ?.setAttribute('href', `data:image/svg+xml,${encodeURIComponent(logoSvg)}`);
    } else {
      console.error('customer logo is not an SVG!');
    }
  }

  private setStyleSheet(systemConfig: SystemConfiguration) {
    const configuredTheme = localStorage.getItem('configuredTheme');
    let themeName = 'default';

    if (
      configuredTheme &&
      systemConfig.ui.system.alternativeThemes &&
      systemConfig.ui.system.alternativeThemes[configuredTheme]
    ) {
      themeName = configuredTheme;
    }
    this.getApi()
      .pipe(
        (first(),
        tap((res) => {
          if (res?.length) {
            const themeUrl = res + `/assets/themes/theme-${themeName}.css`;
            loadStyleSheet(themeUrl);
            this.loadCssVariables();
          }
        })),
      )
      .subscribe();
  }
  refreshProjectConfig() {
    this.getApi('metadata_service')
      .pipe(
        switchMap((baseUrl) => {
          return this.http
            .get(baseUrl + '/get-protected-project-config', {
              params: { configKey: 'project' },
            })
            .pipe(
              tap((res: any) => {
                this.kycRegulated = res.kyc_regulated;
                this.supportedCurrencies = res.supportedCurrency;
                if (res?.provider)
                  this.store.dispatch(
                    new UpdatePaymentGatewayConfig({
                      payment: res.provider,
                      sandboxMode: res?.sandboxMode,
                      supportedCurrency: res.supportedCurrency,
                      bankProvider: res.bankProvider,
                      checkPayeeEnabled: res.checkPayeeEnabled,
                      paymentRequest: res.paymentRequest,
                    }),
                  );
                this.store.dispatch(
                  new UpdateProjectSettings({
                    onboardingFlow: res?.onboardingFlow,
                    defaultCurrency: res?.defaultCurrency,
                    checkPayeeEnabled: res?.checkPayeeEnabled,
                    smsAuthenticationEnabled: res?.smsAuthenticationEnabled,
                    smsAuthenticationExclusion: res?.smsAuthenticationExclusion,
                    locale: res.defaultLocale ?? Locale.en_GB,
                    enableBeneficiaryFirstAndLastName: res?.enableBeneficiaryFirstAndLastName,
                    feeManagement: {
                      enabled: res?.feeManagement?.enabled,
                      retryFailedFeeEnabled: res?.feeManagement?.retryFailedFeeEnabled,
                      feeReversalEnabled: res?.feeManagement?.feeReversalEnabled,
                    },
                  }),
                );
              }),
              catchError((err) => {
                console.error(err);
                return throwError(() => err);
              }),
            );
        }),
      )
      .subscribe();
  }

  public getSupportedCurrencies(): Observable<string[]> {
    return of(this.supportedCurrencies);
  }

  refreshConfig() {
    this.http
      .get<any>('./assets/config.json')
      .pipe(
        tap((data) => {
          const config = plainToClass(Config, data);
          this.config = config;
          this._config$.next(config);
        }),
        catchError((err) => {
          console.error(err);
          return throwError(() => err);
        }),
      )
      .subscribe();
  }

  getFeatureFlags() {
    return this._config$.pipe(map((config) => config?.['features']));
  }

  getFeatureFlagProvider() {
    return this._config$.pipe(
      map((config) => ({
        featureFlagProvider: config?.['featureFlagProvider'],
        auth: config?.['auth'],
      })),
    );
  }

  getApi(key = 'default'): Observable<string> {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return this._config$.pipe(map((config) => config['api_endpoints'][key]!));
  }

  getApiConfig(): Observable<Map<string, string>> {
    return this._config$.pipe(map((config) => config['api_endpoints']));
  }

  getCountries(): Observable<Country[]> {
    return this._countries$.asObservable();
  }

  getCurrencies(): Observable<Currency[]> {
    return this.countriesCurrenciesService.getCurrencies();
  }

  getProjectPaymentProvider(): Observable<PaymentProvider> {
    return this.projectPaymentProviderObs.asObservable();
  }

  getCustomQuestionData(questionUniqueId: string) {
    return this._systemConfiguration$.pipe(
      distinctUntilChanged(),
      map((systemConfig: SystemConfiguration) => {
        return systemConfig.ui.customData.questions[removeSpaceFromString(questionUniqueId)];
      }),
      catchError((err) => {
        console.error(err);
        return of();
      }),
    );
  }

  public checkZoneExist(zoneName: APP_ZONES) {
    return this._systemConfiguration$.pipe(
      distinctUntilChanged(),
      map((systemConfig: SystemConfiguration) => {
        return systemConfig?.ui?.zones?.[zoneName];
      }),
      catchError((err) => {
        console.error(err);
        return of();
      }),
    );
  }

  getCustomDataQuestionData(questionUniqueId: string) {
    return this._systemConfiguration$.pipe(
      distinctUntilChanged(),
      map((systemConfig: SystemConfiguration) => {
        return systemConfig.ui.customData.data[this.removeSpaceFromRole(questionUniqueId)];
      }),
      catchError((err) => {
        console.error(err);
        return of();
      }),
    );
  }

  private fetchDeviceOrMobile(
    systemConfig: SystemConfiguration,
    roleInfo: RoleStateModel,
    zone: string,
  ): UiPageConfig {
    this.collectRouteParams();
    const config = systemConfig.ui.zones[zone].roles[roleInfo.role]?.[roleInfo.subscription][roleInfo.device];

    const pageIndex = config.pages.findIndex((page) => page.url === this.zoneUrls.pageUrl);

    const layoutData = config.pages[pageIndex];

    return layoutData;
  }

  private fetchDeviceOrMobilePageConfig(
    systemConfig: SystemConfiguration,
    roleInfo: RoleStateModel,
    zone: string,
    pageIndex: number,
  ): UiPageConfig {
    this.collectRouteParams();
    const config = systemConfig.ui.zones[zone].roles[roleInfo.role]?.[roleInfo.subscription][roleInfo.device];

    const page = config.pages[pageIndex];
    if (page) {
      return page;
    } else {
      throw Error(`Tried to fetch unknown pageIndex: ${pageIndex} in zone: ${zone}`);
    }
  }

  getCardManagementWidgets(roleInfo: RoleStateModel): Observable<UiPageConfig> {
    return this._systemConfiguration$.pipe(
      distinctUntilChanged(),
      map((systemConfig: SystemConfiguration) => {
        return this.fetchDeviceOrMobile(systemConfig, roleInfo, APP_ZONES.LANDING);
      }),
      catchError((err, cause) => {
        console.error(err);
        return of();
      }),
    );
  }

  getCardManagementZoneWidgetsLayout(roleInfo: RoleStateModel): Observable<UiPageConfig> {
    return this._systemConfiguration$.pipe(
      map((systemConfig) => {
        return this.fetchDeviceOrMobile(systemConfig, roleInfo, APP_ZONES.CARD_MANAGEMENT_ZONE_DEMO);
      }),
      catchError((err, cause) => {
        console.error(err);
        return of();
      }),
    );
  }

  getCurrentPageStateData(): Observable<WorkflowPageStateConfig | null> {
    return this._systemConfiguration$.pipe(
      distinctUntilChanged(),
      map((_) => {
        return null;
      }),
      catchError((err, cause) => {
        console.error(err);
        return of();
      }),
    );
  }

  private getCurrentPageIndex(
    systemConfig: SystemConfiguration,
    roleInfo: RoleStateModel,
    zone: string,
  ): number {
    const config = systemConfig.ui.zones[zone].roles[roleInfo.role]?.[roleInfo.subscription][roleInfo.device];
    return config.pages.findIndex((page) => page.url === this.zoneUrls.pageUrl);
  }

  getPageUrlPageIndex(zone: string, pageUrl: string): Observable<number | undefined> {
    this.collectRouteParams();
    return this.getSystemConfigAndZone().pipe(
      take(1),
      map(([systemConfig, zoneInfo]) => {
        const config =
          systemConfig.ui.zones[zoneInfo.zone].roles[this.roleInfo.role]?.[this.roleInfo.subscription][
            this.roleInfo.device
          ];

        return config?.pages?.findIndex((page) => page.url === pageUrl);
      }),
    );
  }

  getPageUrlPage(pageUrl: string): Observable<UiPageConfig | undefined> {
    this.collectRouteParams();
    return this.getSystemConfigAndZone().pipe(
      take(1),
      map(([systemConfig, zoneInfo]) => {
        const config =
          systemConfig.ui.zones[zoneInfo.zone].roles[this.roleInfo.role]?.[this.roleInfo.subscription][
            this.roleInfo.device
          ];
        return config?.pages?.find((page) => page.url === pageUrl);
      }),
    );
  }

  public getZoneWithNoRoleContext(): Observable<SystemZoneDetail | undefined> {
    // Note we have no role context at this point
    this.collectRouteParams();
    return this.getWidgetsConfig().pipe(
      take(1),
      map((config) => {
        const entry = Object.entries(config.zones).find(([key, value]) => {
          return formatNameAsUrl(key) === this.zoneUrls.zoneUrl;
        });

        return entry?.[1];
      }),
    );
  }

  getMobileNavBarItems(): Observable<NavBarMobile> {
    return this._systemConfiguration$.pipe(
      map((systemConfig) => {
        return systemConfig.ui.navbar[this.roleInfo.role]?.navBarMobile;
      }),
    );
  }

  getPageIndexPageUrl(zone: string, pageIndex: number, role?: string): string | undefined {
    const roleSearch = role ? role : this.roleInfo.role;
    let pageUrl: string | undefined;
    this.getSystemConfigAndZone()
      .pipe(take(1))
      .subscribe(([systemConfig, zoneInfo]) => {
        const config =
          systemConfig.ui.zones[zone && zone.length > 0 ? zone : zoneInfo.zone].roles[roleSearch]?.[
            this.roleInfo.subscription
          ][this.roleInfo.device];

        pageUrl = config?.pages[pageIndex]?.url;
      });
    return pageUrl;
  }

  getOnboardingZoneLayout(
    role: string,
    subscription: string,
    device: SupportedDevice,
  ): Observable<UiPageConfig> {
    const roleInfo = {
      role: role,
      subscription: subscription,
      device: device,
    };
    return this._systemConfiguration$.pipe(
      map((systemConfig: SystemConfiguration) => {
        const pageIndex = this.getCurrentPageIndex(systemConfig, roleInfo, APP_ZONES.ONBOARDING);
        return this.fetchDeviceOrMobilePageConfig(systemConfig, roleInfo, APP_ZONES.ONBOARDING, pageIndex);
      }),
      catchError((err, cause) => {
        console.error(err);
        return of();
      }),
    );
  }

  getNavBarDesktopConfig(): Observable<NavBarDesktop> {
    return this.activeOrgId$.pipe(
      take(1),
      switchMap(() =>
        this.newRoleSet.pipe(
          take(1), // Ensure we take one value from newRoleSet
          switchMap(() =>
            this._systemConfiguration$.pipe(
              distinctUntilChanged((prev, curr) => {
                return JSON.stringify(prev) === JSON.stringify(curr);
              }),
              map((systemConfig: SystemConfiguration) => {
                return systemConfig.ui.navbar[this.roleInfo.role].navBarDesktop;
              }),
              catchError((err) => {
                console.error(err);
                return of();
              }),
            ),
          ),
        ),
      ),
    );
  }

  getUpdatedSystemConfig(): Observable<SystemConfiguration> {
    return this.activeOrgId$.pipe(
      take(1),
      switchMap(() =>
        this.newRoleSet.pipe(
          take(1), // Ensure we take one value from newRoleSet
          switchMap(() =>
            this._systemConfiguration$.pipe(
              map((systemConfig: SystemConfiguration) => {
                return systemConfig;
              }),
              catchError((err) => {
                console.error(err);
                return of();
              }),
            ),
          ),
        ),
      ),
    );
  }

  getCustomQuestion(unique_id: string) {
    let question: customDataQuestionType = {
      questionUniqueId: '',
      type: '',
      header: '',
      subHeader: '',
      questionProperties: {
        name: '',
        label: '',
        type: '',
        options: [],
        preSelectSubAnswer: '',
      },
      validationRules: [],
    };
    this._systemConfiguration$.subscribe((res) => {
      question = res.ui?.customData?.questions?.[formatNameAsUrl(unique_id)];
    });
    return question;
  }

  getPrivacyPageWidgetLayout(): Observable<UiPageConfig> {
    return of({
      url: '',
      mainComponentName: '',
      widgetSections: [],
      pageTypeConfig: 'new',
      pageType: 'staticGrid',
      title: '',
      widgetLayouts: [
        {
          name: WidgetNames.USER_PRIVACY_WIDGET,
          uniqueId: WidgetNames.USER_PRIVACY_WIDGET,
          gridPosition: defaultGridPosition(),
          attributes: {
            css_config: defaultCssProperties(),
            elementsConfig: [],
            widgetProperties: { textContent: '' },
          },
        },
      ],
      pageConfiguration: {
        gridsterMargin: {
          left: 1,
          right: 1,
          top: 2,
          unit: 'em',
        },
        showHeader: true,
        labelsAsPlaceholder: false,
      },
    });
  }

  getProfileZone(): Observable<UiPageConfig> {
    return this._systemConfiguration$.pipe(
      map((systemConfig: SystemConfiguration) => {
        const pageIndex = this.getCurrentPageIndex(systemConfig, this.roleInfo, APP_ZONES.PROFILE);

        return systemConfig.ui.zones[APP_ZONES.PROFILE].roles[this.roleInfo.role][this.roleInfo.subscription][
          this.roleInfo.device
        ].pages[pageIndex];
      }),
      catchError((err) => {
        console.error(err);
        return of();
      }),
    );
  }

  getTheme(): Observable<ThemeConfig> {
    return this._systemConfiguration$.pipe(
      map((systemConfig: SystemConfiguration) => {
        const configuredTheme = localStorage.getItem('configuredTheme');
        if (
          configuredTheme &&
          systemConfig.ui.system.alternativeThemes &&
          systemConfig.ui.system.alternativeThemes[configuredTheme]
        ) {
          return systemConfig.ui.system.alternativeThemes[configuredTheme].theme;
        } else {
          return systemConfig.ui.system.theme;
        }
      }),
      catchError((err, cause) => {
        console.error(err);
        return of(defaultTheme);
      }),
    );
  }

  setRoleInfo(token: string): void {
    try {
      const jwt: any = jwt_decode(token);
      const organisation = jwt.organisation;

      combineLatest([this.activeOrgId$, this._systemConfiguration$.pipe(take(1))]).subscribe(
        ([activeOrgId, systemConfig]) => {
          // DO NOT let the setRole actions fire if I'm in a signed out zone
          if (isAuthenticatedRoute(window.location.pathname)) {
            // grab all personas in the app
            const personas = Object.keys(systemConfig.roles);
            // If canvas for role does not exist then the landing page fails
            // to render even if the active org id + persona has a canvas as first one wins
            const organisationId = activeOrgId ?? Object.keys(organisation)[0];
            // Filter out permissions roles vs app personas to just get personas
            const filterPersonas = organisation[organisationId].roles.filter((role: string) => {
              return personas.includes(role);
            });
            const deviceInfo = this.deviceDetectorService.getDeviceInfo();
            // short term hack to get tablet working as a mobile screen for now
            if (deviceInfo.deviceType === 'tablet') {
              this.store.dispatch(new SetRole(this.removeSpaceFromRole(filterPersonas[0]), 'mobile'));
            } else {
              this.store.dispatch(
                new SetRole(
                  this.removeSpaceFromRole(filterPersonas[0]),
                  deviceInfo.deviceType as SupportedDevice,
                ),
              );
            }
          }
        },
      );
    } catch (error) {
      if (error instanceof InvalidTokenError) {
        console.error(
          'error:setRoleInfo - have got an invalid token going to force a refresh of the page',
          error,
        );
      }
      console.error('error:setRoleInfo', error);
      Sentry.captureException(error);
      window.location.reload();
    }
  }

  setActiveRole(role: string): void {
    try {
      const deviceInfo = this.deviceDetectorService.getDeviceInfo();
      // short term hack to get tablet working as a mobile screen for now
      if (deviceInfo.deviceType === 'tablet') {
        this.store.dispatch(new SetRole(this.removeSpaceFromRole(role), 'mobile'));
      } else {
        this.store.dispatch(
          new SetRole(this.removeSpaceFromRole(role), deviceInfo.deviceType as SupportedDevice),
        );
      }
    } catch (error) {
      console.error('error:setActiveRole', error);
      return;
    }
  }

  removeSpaceFromRole = (role: string) => {
    try {
      return role.toLowerCase().replace(/\s/g, '-');
    } catch (error) {
      return '';
    }
  };

  getSystemName(): Observable<string> {
    return this._systemConfiguration$.pipe(
      map((systemConfig: SystemConfiguration) => {
        const configuredTheme = localStorage.getItem('configuredTheme');
        let systemName = systemConfig.ui.system.systemName;
        if (
          configuredTheme &&
          systemConfig.ui.system.alternativeThemes &&
          systemConfig.ui.system.alternativeThemes[configuredTheme]
        ) {
          systemName = systemConfig.ui.system.alternativeThemes[configuredTheme].systemName ?? systemName;
        }
        return systemName;
      }),
      catchError((err, cause) => {
        console.error(err);
        return of('MoneyFin');
      }),
    );
  }

  getLegalEntityName(): Observable<string> {
    return this._systemConfiguration$.pipe(
      map((systemConfig: SystemConfiguration) => {
        const configuredTheme = localStorage.getItem('configuredTheme');
        let systemName = systemConfig.ui.system.systemName;
        let legalEntityName = systemConfig.ui.system.legalEntityName;
        if (
          configuredTheme &&
          systemConfig.ui.system.alternativeThemes &&
          systemConfig.ui.system.alternativeThemes[configuredTheme]
        ) {
          systemName = systemConfig.ui.system.alternativeThemes[configuredTheme].systemName ?? systemName;
          legalEntityName =
            systemConfig.ui.system.alternativeThemes[configuredTheme].legalEntityName ?? legalEntityName;
        }
        return systemName ?? legalEntityName;
      }),
      catchError((err, cause) => {
        console.error(err);
        return of('MoneyFin');
      }),
    );
  }

  getAuthConfig(): Observable<AuthConfig> {
    return this._config$.pipe(map((config) => config.auth));
  }

  getConfig(): Observable<Config> {
    return this._config$.asObservable();
  }

  getWidgetsConfig(): Observable<UiSystemConfig> {
    return this._systemConfiguration$.pipe(map((v) => v.ui));
  }

  getCurrentAndNewWidgetsConfig(
    newZone: string,
    newPageUrl: string,
    newPersona?: string,
  ): Observable<[UiZoneWidgetConfig[], UiZoneWidgetConfig[]]> {
    return this.getSystemConfigAndZone().pipe(
      map(([systemConfig, { zone, pageIndex }]) => {
        if (this.roleInfo?.role) {
          const currentZone = zone;
          const currentPageIndex = pageIndex;
          const currentConfig =
            systemConfig.ui.zones[currentZone].roles[this.roleInfo.role]?.[this.roleInfo.subscription][
              this.roleInfo.device
            ];
          const currentPage = currentConfig.pages[currentPageIndex];

          if (currentPage) {
            const newZoneName =
              Object.keys(systemConfig.ui.zones).find((key) => formatNameAsUrl(key) === newZone) ?? '';

            const newConfig =
              systemConfig.ui.zones[newZoneName].roles[newPersona ? newPersona : this.roleInfo.role]?.[
                this.roleInfo.subscription
              ][this.roleInfo.device];

            const newPageIndex = newConfig.pages.findIndex((page) => {
              return page.url === newPageUrl;
            });

            const newPage = newConfig.pages[newPageIndex];

            if (currentPage) {
              return [currentPage.widgetLayouts, newPage.widgetLayouts];
            } else {
              throw Error(`Tried to fetch unknown pageUrl: ${newPageUrl} in zone: ${newZone}`);
            }
          } else {
            throw Error(`Tried to fetch unknown pageIndex: ${currentPageIndex} in zone: ${currentZone}`);
          }
        } else {
          return [[], []];
        }
      }),
    );
  }

  getCurrentRoleZoneWidgetsConfig(
    zoneOverride?: string,
    pageIndexOveride?: number,
  ): Observable<UiPageConfig> {
    return this.getSystemConfigAndZone().pipe(
      map(([systemConfig, { zone, pageIndex }]) => {
        if (this.roleInfo?.role) {
          if (zoneOverride) zone = zoneOverride;
          if (pageIndexOveride) pageIndex = pageIndexOveride;

          return this.fetchDeviceOrMobilePageConfig(systemConfig, this.roleInfo, zone, pageIndex);
        } else {
          return {} as UiPageConfig;
        }
      }),
    );
  }

  getCurrentRoleZonePagesConfig(zoneInfo: Record<string, string | number> = {}) {
    return combineLatest([this.getWidgetsConfig(), this.getZoneByRoute()]).pipe(
      filter(([uiSystemConfig, zoneConfig]) => {
        const zone = zoneInfo['zone'] ?? zoneConfig['zone'];

        const roles = uiSystemConfig.zones[zone]?.roles;
        return roles && Object.keys(roles).length > 0;
      }),
      map(([uiSystemConfig, zoneConfig]) => {
        const zone = zoneInfo['zone'] ?? zoneConfig['zone'];
        const role = zoneInfo?.['role'] ?? this.roleInfo.role;
        const subscription = zoneInfo?.['subscription'] ?? this.roleInfo.subscription;

        const config = uiSystemConfig.zones[zone].roles[role][subscription][this.roleInfo.device];
        return config.pages;
      }),
    );
  }

  public defaultToSignedOutIfNeeded() {
    this.collectRouteParams();
    // If I go to the signed out zone reset role back to 'signed-out'
    if (
      this.zoneUrls.zoneUrl === formatNameAsUrl(APP_ZONES.SIGNED_OUT_FIRST_INTRODUCTION) ||
      this.zoneUrls.zoneUrl === formatNameAsUrl(APP_ZONES.SIGNED_OUT_INTRODUCTION) ||
      this.zoneUrls.zoneUrl === formatNameAsUrl(APP_ZONES.SIGNED_OUT_FORGOT_PASSWORD)
    ) {
      const deviceInfo = this.deviceDetectorService.getDeviceInfo();
      this.store.dispatch(new SetRole('signed-out', deviceInfo.deviceType as SupportedDevice));
    }
  }

  fetchCurrentZoneConfiguration(): Observable<ZoneConfiguration> {
    this.collectRouteParams();
    this.defaultToSignedOutIfNeeded();

    return combineLatest([this._systemConfiguration$, this.getZoneByRoute()]).pipe(
      map(([systemConfig, zone]) => {
        const config =
          systemConfig.ui.zones[zone.zone].roles[this.roleInfo.role]?.[this.roleInfo.subscription][
            this.roleInfo.device
          ];

        return config.zoneConfiguration;
      }),
    );
  }

  private getPageStateConfigData(
    systemConfig: SystemConfiguration,
    roleInfo: RoleStateModel,
    widgetName: string,
    activeZone: string,
    pageIndex = 0,
  ): WorkflowPageStateConfig {
    const config =
      systemConfig.ui.zones[activeZone]?.roles[roleInfo.role]?.[roleInfo.subscription]?.[roleInfo.device];

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion, @typescript-eslint/no-non-null-asserted-optional-chain
    return config.pages[pageIndex]?.widgetSections?.find((x) => x.name == widgetName)!;
  }

  getCardActivationConfigData(): Observable<WorkflowPageStateConfig> {
    return combineLatest([this._systemConfiguration$, this.getZoneByRoute()]).pipe(
      map(([systemConfig, { zone, pageIndex }]) => {
        return this.getPageStateConfigData(
          systemConfig,
          this.roleInfo,
          WidgetNames.CARD_ACTIVATION,
          zone,
          pageIndex,
        );
      }),
      catchError((err, cause) => {
        return of();
      }),
    );
  }

  getSimpleBalanceWidgetConfigData(): Observable<UiZoneWidgetConfig> {
    return this.getSystemConfigAndZone().pipe(
      map(([systemConfig, { zone, pageIndex }]) => {
        return this.fetchWidgetConfig(WidgetNames.SIMPLE_BALANCE_WIDGET, systemConfig, zone, pageIndex);
      }),
      catchError((err, cause) => {
        return of();
      }),
    );
  }

  getActivateCardVerifyOtpPopupConfigData(): Observable<WorkflowPageStateConfig> {
    return this.getSystemConfigAndZone().pipe(
      map(([systemConfig, { zone, pageIndex }]) => {
        return this.getPageStateConfigData(
          systemConfig,
          this.roleInfo,
          WidgetNames.ACTIVATE_CARD_OTP,
          zone,
          pageIndex,
        );
      }),
      catchError((err, cause) => {
        return of();
      }),
    );
  }

  private fetchWidgetConfig(
    widgetName: string,
    systemConfig: SystemConfiguration,
    zone: string,
    pageIndex: number,
  ): UiZoneWidgetConfig {
    const config =
      systemConfig.ui.zones[zone].roles[this.roleInfo.role][this.roleInfo.subscription]?.[
        this.roleInfo.device
      ];

    const result = config.pages[pageIndex].widgetLayouts.find((x) => x.name == widgetName);
    if (result) return result;
    else throw new Error(`No config for: zone: ${zone}, pageIndex: ${pageIndex}`);
  }

  getBeneficiaryCarouselConfig(): Observable<any> {
    return this.getSystemConfigAndZone().pipe(
      map(([systemConfig, { zone, pageIndex }]) => {
        return this.fetchWidgetConfig(WidgetNames.BENEFICIARY_CAROUSEL, systemConfig, zone, pageIndex);
      }),
      catchError((err, cause) => {
        return of();
      }),
    );
  }

  onGetBaseWidgetStyle(widgetConfiguration: any, includeBorderStyle = true): baseCssConfigStyle {
    const borderStyles = {
      'border-top-left-radius': `${widgetConfiguration?.css_config?.borderRadiusTopLeft}${widgetConfiguration?.css_config?.borderRadiusUnit}`,
      'border-top-right-radius': `${widgetConfiguration?.css_config?.borderRadiusTopRight}${widgetConfiguration?.css_config?.borderRadiusUnit}`,
      'border-bottom-left-radius': `${widgetConfiguration?.css_config?.borderRadiusBottomLeft}${widgetConfiguration?.css_config?.borderRadiusUnit}`,
      'border-bottom-right-radius': `${widgetConfiguration?.css_config?.borderRadiusBottomRight}${widgetConfiguration?.css_config?.borderRadiusUnit}`,
      'border-color': `${widgetConfiguration?.css_config?.borderColor}`,
      'border-width': `${widgetConfiguration?.css_config?.borderWidth}px`,
      'border-style': `${widgetConfiguration?.css_config?.borderStyle}`,
    };
    return {
      'margin-top': `${widgetConfiguration?.css_config?.marginTop}${widgetConfiguration?.css_config?.marginUnit}`,
      'margin-left': `${widgetConfiguration?.css_config?.marginLeft}${widgetConfiguration?.css_config?.marginUnit}`,
      'margin-right': `${widgetConfiguration?.css_config?.marginRight}${widgetConfiguration?.css_config?.marginUnit}`,
      'margin-bottom': `${widgetConfiguration?.css_config?.marginBottom}${widgetConfiguration?.css_config?.marginUnit}`,
      'padding-top': `${widgetConfiguration?.css_config?.paddingTop}${widgetConfiguration?.css_config?.paddingUnit}`,
      'padding-left': `${widgetConfiguration?.css_config?.paddingLeft}${widgetConfiguration?.css_config?.paddingUnit}`,
      'padding-right': `${widgetConfiguration?.css_config?.paddingRight}${widgetConfiguration?.css_config?.paddingUnit}`,
      'padding-bottom': `${widgetConfiguration?.css_config?.paddingBottom}${widgetConfiguration?.css_config?.paddingUnit}`,
      'font-size': `${widgetConfiguration?.css_config?.fontSize}${
        widgetConfiguration?.css_config?.fontUnit ?? 'px'
      }`,
      color: `${widgetConfiguration?.css_config?.color}`,
      'text-align': `${widgetConfiguration?.css_config?.textAlignment}`,

      'font-weight': `${widgetConfiguration?.css_config?.fontWeight}`,
      'background-color': `${widgetConfiguration?.css_config?.backGroundColor}`,
      ...(includeBorderStyle ? borderStyles : {}),
      ...this.getBoxShadow(widgetConfiguration),
    };
  }
  onGetLineWidgetStyle(widgetProperties: BaseWidgetProperties): LineAdditionalConfigStyle {
    return {
      line: {
        'border-bottom': `${widgetProperties?.['lineThickness']}px solid ${widgetProperties?.['color']}`,
        width: `${widgetProperties?.['lineWidth']}%`,
      },
      content: {
        'padding-top': `${widgetProperties?.['paddingTop']}px`,
        'padding-bottom': `${widgetProperties?.['paddingBottom']}px`,
        'justify-content': widgetProperties?.['contentAlignment'],
        visibility: widgetProperties?.['show'] ? 'visible' : 'hidden',
      },
    };
  }

  onGetIframeWidgetStyle(widgetConfiguration: IFrameAdditionalConfig): IFrameAdditionalConfigStyle {
    return {
      content: {
        height: widgetConfiguration.verticalHeight,
      },
      baseConfig: {
        'margin-top': `${widgetConfiguration.css_config?.marginTop}${widgetConfiguration.css_config?.marginUnit}`,
        'margin-left': `${widgetConfiguration.css_config?.marginLeft}${widgetConfiguration.css_config?.marginUnit}`,
        'margin-right': `${widgetConfiguration.css_config?.marginRight}${widgetConfiguration.css_config?.marginUnit}`,
        'margin-bottom': `${widgetConfiguration.css_config?.marginBottom}${widgetConfiguration.css_config?.marginUnit}`,
        'padding-top': `${widgetConfiguration.css_config?.paddingTop}${widgetConfiguration.css_config?.paddingUnit}`,
        'padding-left': `${widgetConfiguration.css_config?.paddingLeft}${widgetConfiguration.css_config?.paddingUnit}`,
        'padding-right': `${widgetConfiguration.css_config?.paddingRight}${widgetConfiguration.css_config?.paddingUnit}`,
        'padding-bottom': `${widgetConfiguration.css_config?.paddingBottom}${widgetConfiguration.css_config?.paddingUnit}`,
      },
    };
  }

  // 10 minute timeout
  public imageCache: TTLMap<string, Promise<FileUploadResponse>> = new TTLMap(600000);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async getImagePath(id: string, projectId = ''): Promise<FileUploadResponse> {
    const cacheKey = `${id}`;

    if (!this.imageCache.get(cacheKey)) {
      this.imageCache.set(cacheKey, this.fetchImagePath(id));
    }

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return this.imageCache.get(cacheKey)!;
  }

  private async fetchImagePath(id: string): Promise<FileUploadResponse> {
    let baseUrl = '';

    this.getApi('file_service').subscribe((api: any) => {
      baseUrl = api;
    });
    return await firstValueFrom(
      this.http.get<FileUploadResponse>(baseUrl + '/file', {
        params: {
          id: id,
        },
      }),
    );
  }

  dropDesktop(index: number) {
    console.log(index);
  }

  getDeviceData(): DeviceData {
    let gridCols: number;
    let gridRows = 25;
    let numberCols: number;
    if (this.deviceTypeService.currentdeviceType == 'desktop') {
      numberCols = 48;
      gridCols = window.innerWidth / numberCols;
      gridRows = 40;
    } else if (this.deviceTypeService.currentdeviceType == 'tablet') {
      numberCols = 20;
      gridCols = window.innerWidth / numberCols;
      gridRows = 40;
    } else {
      numberCols = 15;
      gridCols = window.innerWidth / 15;
    }
    return {
      deviceType: this.roleInfo?.device,
      deviceWidth: window.innerWidth,
      deviceHeight: window.innerHeight,
      numberCols: numberCols,
      gridCols: gridCols,
      gridRows: gridRows,
      gridster: false,
    };
  }

  getSpecificBaseCssStyle(
    widgetConfiguration: UiZoneWidgetAttributeConfig,
    propertyName: Array<string>,
  ): baseCssConfigStyle {
    let cssProperty = {};

    propertyName.forEach((cssPropertyName) => {
      switch (cssPropertyName) {
        case BaseCssProperties.MARGIN:
          cssProperty = {
            ...cssProperty,
            ...this.getBaseCssMargin(widgetConfiguration),
          };
          break;

        case BaseCssProperties.PADDING:
          cssProperty = {
            ...cssProperty,
            ...this.getBaseCssPadding(widgetConfiguration),
          };
          break;

        case BaseCssProperties.COLOR:
          cssProperty = {
            ...cssProperty,
            ...this.getBaseCssColor(widgetConfiguration),
          };
          break;

        case BaseCssProperties.ICON_COLOR:
          cssProperty = {
            ...cssProperty,
            ...this.getBaseCssIconColor(widgetConfiguration),
          };
          break;

        case BaseCssProperties.RIGHT_ICON_COLOR:
          cssProperty = {
            ...cssProperty,
            ...this.getBaseCssRightIconColor(widgetConfiguration),
          };
          break;

        case BaseCssProperties.LEFT_ICON_COLOR:
          cssProperty = {
            ...cssProperty,
            ...this.getBaseCssLeftIconColor(widgetConfiguration),
          };
          break;

        case BaseCssProperties.TEXTALIGN:
          cssProperty = {
            ...cssProperty,
            ...this.getBaseCssTextAlign(widgetConfiguration),
          };
          break;

        case BaseCssProperties.BORDER:
          cssProperty = {
            ...cssProperty,
            ...this.getBaseCssBorder(widgetConfiguration),
          };
          break;

        case BaseCssProperties.INPUT_BORDER:
          cssProperty = {
            ...cssProperty,
            ...this.getBaseCssInputBorder(widgetConfiguration),
          };
          break;

        case BaseCssProperties.INPUT_IDLE_BACKGROUND_COLOR:
          cssProperty = {
            ...cssProperty,
            ...this.getBaseCssInputIdleBackgroundColor(widgetConfiguration),
          };
          break;

        case BaseCssProperties.ACTIVE_BACKGROUND_COLOR:
          cssProperty = {
            ...cssProperty,
            ...this.getBaseCssActiveBackgroundColor(widgetConfiguration),
          };
          break;

        case BaseCssProperties.ACTIVE_BORDER_COLOR:
          cssProperty = {
            ...cssProperty,
            ...this.getBaseCssActiveBorder(widgetConfiguration),
          };
          break;

        case BaseCssProperties.IN_ACTIVE_BACKGROUND_COLOR:
          cssProperty = {
            ...cssProperty,
            ...this.getBaseCssInActiveBackgroundColor(widgetConfiguration),
          };
          break;

        case BaseCssProperties.IN_ACTIVE_BORDER_COLOR:
          cssProperty = {
            ...cssProperty,
            ...this.getBaseCssInActiveBorder(widgetConfiguration),
          };
          break;

        case BaseCssProperties.INPUT_IDLE_BORDER_COLOR:
          cssProperty = {
            ...cssProperty,
            ...this.getBaseCssInputIdleBorderColor(widgetConfiguration),
          };
          break;

        case BaseCssProperties.INPUT_ACTIVE_BACKGROUND_COLOR:
          cssProperty = {
            ...cssProperty,
            ...this.getBaseCssInputActiveBackgroundColor(widgetConfiguration),
          };
          break;

        case BaseCssProperties.SELECTED_BORDER:
          cssProperty = {
            ...cssProperty,
            ...this.getBaseCssSelectedBorder(widgetConfiguration),
          };
          break;

        case BaseCssProperties.BACKGROUND_COLOR:
          cssProperty = {
            ...cssProperty,
            ...this.getBaseCssBackGroundColor(widgetConfiguration),
          };
          break;

        case BaseCssProperties.V_ALIGN:
          cssProperty = {
            ...cssProperty,
            ...this.getBaseCssVerticalAlign(widgetConfiguration),
          };
          break;

        case BaseCssProperties.V_ALIGN_DISPLAY_BLOCK:
          cssProperty = {
            ...cssProperty,
            ...this.getBaseCssVerticalAlignDisplayBlock(widgetConfiguration),
          };
          break;

        case BaseCssProperties.DISABLE_BACKGROUND_COLOR:
          cssProperty = {
            ...cssProperty,
            ...this.getBaseCssDisableBackGroundColor(widgetConfiguration),
          };
          break;

        case BaseCssProperties.DISABLE_COLOR:
          cssProperty = {
            ...cssProperty,
            ...this.getBaseCssDisableColor(widgetConfiguration),
          };
          break;

        case BaseCssProperties.OBJECT_FIT:
          cssProperty = {
            ...cssProperty,
            ...this.getBaseCssObjectFit(widgetConfiguration),
          };
          break;

        case BaseCssProperties.FONT_SIZE:
          cssProperty = {
            ...cssProperty,
            ...this.getBaseCssFontSize(widgetConfiguration),
          };
          break;

        case BaseCssProperties.TEXT_BOLD:
          cssProperty = {
            ...cssProperty,
            ...this.getBaseCssTextBold(widgetConfiguration),
          };
          break;

        case BaseCssProperties.TEXT_ITALIC:
          cssProperty = {
            ...cssProperty,
            ...this.getBaseCssTextItalic(widgetConfiguration),
          };
          break;

        case BaseCssProperties.TEXT_UNDERLINE:
          cssProperty = {
            ...cssProperty,
            ...this.getBaseCssTextUnderline(widgetConfiguration),
          };
          break;

        case BaseCssProperties.TEXT_FONT_STYLE:
          cssProperty = {
            ...cssProperty,
            ...this.getTextFontStyle(widgetConfiguration),
          };
          break;

        case BaseCssProperties.BOX_SHADOW:
          cssProperty = {
            ...cssProperty,
            ...this.getBoxShadow(widgetConfiguration),
          };
          break;
        case BaseCssProperties.TEXT_INPUT_FONT_SIZE:
          cssProperty = {
            ...cssProperty,
            ...this.getTextInputFontSize(widgetConfiguration),
          };
          break;
        case BaseCssProperties.TEXT_INPUT_BG_COLOR:
          cssProperty = {
            ...cssProperty,
            ...this.getTextInputBackgroundColor(widgetConfiguration),
          };
          break;
        case BaseCssProperties.ICON_SIZE:
          cssProperty = {
            ...cssProperty,
            ...this.getIconSize(widgetConfiguration),
          };
          break;

        case BaseCssProperties.LEFT_ICON_SIZE:
          cssProperty = {
            ...cssProperty,
            ...this.getLeftIconSize(widgetConfiguration),
          };
          break;

        case BaseCssProperties.RIGHT_ICON_SIZE:
          cssProperty = {
            ...cssProperty,
            ...this.getRightIconSize(widgetConfiguration),
          };
          break;
        case BaseCssProperties.DIMENSIONS:
          cssProperty = {
            ...cssProperty,
            ...this.getDimensions(widgetConfiguration),
          };
          break;
        default:
          break;
      }
    });

    return cssProperty;
  }

  public getFontWeight(widgetConfiguration: UiZoneWidgetAttributeConfig) {
    return {
      'font-weight': `${widgetConfiguration?.css_config?.fontWeight}`,
    };
  }
  getTextFontStyle(widgetConfiguration: UiZoneWidgetAttributeConfig) {
    if (widgetConfiguration?.css_config?.textFont?.includes('var(')) {
      return {
        'font-family': `${widgetConfiguration?.css_config?.textFont}`,
      };
    }
    return {
      'font-family': `"${widgetConfiguration?.css_config?.textFont}"`,
    };
  }

  getBoxShadow(widgetConfiguration: UiZoneWidgetAttributeConfig) {
    return {
      'box-shadow': `${widgetConfiguration?.css_config?.shadowOffsetX + 'px'} ${
        widgetConfiguration?.css_config?.shadowOffsetY + 'px'
      } ${widgetConfiguration?.css_config?.shadowBlur + 'px'} ${
        widgetConfiguration?.css_config?.shadowSpread + 'px'
      } ${widgetConfiguration?.css_config?.shadowColor}`,
    };
  }

  getBaseCssMargin(widgetConfiguration: UiZoneWidgetAttributeConfig) {
    return {
      'margin-top': `${widgetConfiguration?.css_config?.marginTop}${widgetConfiguration?.css_config?.marginUnit}`,
      'margin-left': `${widgetConfiguration?.css_config?.marginLeft}${widgetConfiguration?.css_config?.marginUnit}`,
      'margin-right': `${widgetConfiguration?.css_config?.marginRight}${widgetConfiguration?.css_config?.marginUnit}`,
      'margin-bottom': `${widgetConfiguration?.css_config?.marginBottom}${widgetConfiguration?.css_config?.marginUnit}`,
    };
  }

  getBaseCssPadding(widgetConfiguration: UiZoneWidgetAttributeConfig) {
    return {
      'padding-top': `${widgetConfiguration?.css_config?.paddingTop}${widgetConfiguration?.css_config?.paddingUnit}`,
      'padding-left': `${widgetConfiguration?.css_config?.paddingLeft}${widgetConfiguration?.css_config?.paddingUnit}`,
      'padding-right': `${widgetConfiguration?.css_config?.paddingRight}${widgetConfiguration?.css_config?.paddingUnit}`,
      'padding-bottom': `${widgetConfiguration?.css_config?.paddingBottom}${widgetConfiguration?.css_config?.paddingUnit}`,
    };
  }

  getBaseCssColor(widgetConfiguration: UiZoneWidgetAttributeConfig) {
    return {
      color: `${widgetConfiguration?.css_config?.color}`,
    };
  }

  getBaseCssIconColor(widgetConfiguration: UiZoneWidgetAttributeConfig) {
    return {
      color: `${widgetConfiguration?.css_config?.iconColor}`,
    };
  }

  getBaseCssRightIconColor(widgetConfiguration: UiZoneWidgetAttributeConfig) {
    return {
      color: `${widgetConfiguration?.css_config?.rightIconColor}`,
    };
  }

  getBaseCssLeftIconColor(widgetConfiguration: UiZoneWidgetAttributeConfig) {
    return {
      color: `${widgetConfiguration?.css_config?.leftIconColor}`,
    };
  }

  getBaseCssDisableColor(widgetConfiguration: UiZoneWidgetAttributeConfig) {
    return {
      color: `${widgetConfiguration?.css_config?.disabledTextColor}`,
    };
  }

  getBaseCssObjectFit(widgetConfiguration: UiZoneWidgetAttributeConfig) {
    return {
      'background-size': `${
        widgetConfiguration?.css_config?.selectedobjectfit == 'fill'
          ? 'inherit'
          : widgetConfiguration?.css_config?.selectedobjectfit
      }`,
      'object-fit': `${widgetConfiguration?.css_config?.selectedobjectfit}`,
      opacity: `${widgetConfiguration?.css_config?.selectedOpacity / 100}`,
    };
  }
  public getDimensions(widgetConfiguration: UiZoneWidgetAttributeConfig) {
    return {
      height: `${widgetConfiguration?.css_config?.height}${widgetConfiguration?.css_config?.dimensionUnit}`,
      width: `${widgetConfiguration?.css_config?.width}${widgetConfiguration?.css_config?.dimensionUnit}`,
    };
  }

  private getIconSize(widgetConfiguration: { css_config: BaseCssConfig }) {
    return {
      height: `${widgetConfiguration?.css_config?.iconSize}${widgetConfiguration?.css_config?.iconUnit}`,
      width: `${widgetConfiguration?.css_config?.iconSize}${widgetConfiguration?.css_config?.iconUnit}`,
    };
  }

  private getLeftIconSize(widgetConfiguration: { css_config: BaseCssConfig }) {
    return {
      height: `${widgetConfiguration?.css_config?.lefticonSize}${widgetConfiguration?.css_config?.leftIconUnit}`,
      width: `${widgetConfiguration?.css_config?.lefticonSize}${widgetConfiguration?.css_config?.leftIconUnit}`,
    };
  }

  private getRightIconSize(widgetConfiguration: { css_config: BaseCssConfig }) {
    return {
      height: `${widgetConfiguration?.css_config?.righticonSize}${widgetConfiguration?.css_config?.rightIconUnit}`,
      width: `${widgetConfiguration?.css_config?.righticonSize}${widgetConfiguration?.css_config?.rightIconUnit}`,
    };
  }

  private getBaseCssFontSize(widgetConfiguration: UiZoneWidgetAttributeConfig) {
    return {
      'font-size': `${widgetConfiguration?.css_config?.fontSize}${
        widgetConfiguration?.css_config?.fontUnit ?? 'px'
      }`,
    };
  }

  private getTextInputFontSize(widgetConfiguration: UiZoneWidgetAttributeConfig) {
    return {
      'font-size': `${widgetConfiguration?.css_config?.textInputFontSize}${
        widgetConfiguration?.css_config?.textInputFontUnit ?? 'px'
      }`,
    };
  }

  private getBaseCssTextBold(widgetConfiguration: UiZoneWidgetAttributeConfig) {
    if (!widgetConfiguration?.css_config?.isTextBold) {
      return {};
    }
    return {
      'font-weight': `${widgetConfiguration?.css_config?.isTextBold ? 'bold' : 'unset'}`,
    };
  }

  private getBaseCssTextItalic(widgetConfiguration: UiZoneWidgetAttributeConfig) {
    if (!widgetConfiguration?.css_config?.isiTextItalic) {
      return {};
    }
    return {
      'font-style': `${widgetConfiguration?.css_config?.isiTextItalic ? 'italic' : 'unset'}`,
    };
  }

  private getBaseCssTextUnderline(widgetConfiguration: UiZoneWidgetAttributeConfig) {
    if (!widgetConfiguration?.css_config?.isTextUnderline) {
      return {};
    }
    return {
      'text-decoration': `${widgetConfiguration?.css_config?.isTextUnderline ? 'underline' : 'unset'}`,
    };
  }

  getBaseCssTextAlign(widgetConfiguration: UiZoneWidgetAttributeConfig) {
    return {
      'text-align': `${widgetConfiguration?.css_config?.textAlignment}`,
      'justify-content': `${widgetConfiguration?.css_config?.textAlignment}`,
      display: `flex`,
    };
  }

  getBaseCssBackGroundColor(widgetConfiguration: UiZoneWidgetAttributeConfig) {
    return {
      'background-color': `${widgetConfiguration?.css_config?.backGroundColor}`,
    };
  }

  getTextInputBackgroundColor(widgetConfiguration: UiZoneWidgetAttributeConfig) {
    return {
      'background-color': `${widgetConfiguration?.css_config?.textInputBackgroundColor}`,
    };
  }

  getBaseCssDisableBackGroundColor(widgetConfiguration: UiZoneWidgetAttributeConfig) {
    return {
      'background-color': `${widgetConfiguration?.css_config?.disabledBgColor}`,
    };
  }

  getBaseCssInputIdleBackgroundColor(widgetConfiguration: UiZoneWidgetAttributeConfig) {
    return {
      'background-color': `${widgetConfiguration?.css_config?.inputIdleBackgroundColor}`,
    };
  }

  getBaseCssActiveBackgroundColor(widgetConfiguration: UiZoneWidgetAttributeConfig) {
    return {
      'background-color': `${widgetConfiguration?.css_config?.['activeBackgroundColor']}`,
    };
  }

  getBaseCssActiveBorder(widgetConfiguration: UiZoneWidgetAttributeConfig) {
    return {
      'border-color': `${widgetConfiguration?.css_config?.activeBorderColor}`,
      'border-width': `${widgetConfiguration?.css_config?.inputBorderWidth}px`,
      'border-style': `${widgetConfiguration?.css_config?.inputBorderWidth === undefined ? '' : 'solid'}`,
    };
  }

  getBaseCssInActiveBackgroundColor(widgetConfiguration: UiZoneWidgetAttributeConfig) {
    return {
      'background-color': `${widgetConfiguration?.css_config?.['inactiveBackgroundColor']}`,
    };
  }

  getBaseCssInActiveBorder(widgetConfiguration: UiZoneWidgetAttributeConfig) {
    return {
      'border-color': `${widgetConfiguration?.css_config?.inactiveBorderColor}`,
      'border-width': `${widgetConfiguration?.css_config?.inputBorderWidth}px`,
      'border-style': `${widgetConfiguration?.css_config?.inputBorderWidth === undefined ? '' : 'solid'}`,
    };
  }

  getBaseCssInputIdleBorderColor(widgetConfiguration: UiZoneWidgetAttributeConfig) {
    return {
      'border-color': `${widgetConfiguration?.css_config?.inputIdleBorderColor}`,
    };
  }

  getBaseCssInputActiveBackgroundColor(widgetConfiguration: UiZoneWidgetAttributeConfig) {
    return {
      'background-color': `${widgetConfiguration?.css_config?.inputActiveBackgroundColor}`,
    };
  }

  getBaseCssBorder(widgetConfiguration: UiZoneWidgetAttributeConfig) {
    return {
      'border-top-left-radius': `${widgetConfiguration?.css_config?.borderRadiusTopLeft}${widgetConfiguration?.css_config?.borderRadiusUnit}`,
      'border-top-right-radius': `${widgetConfiguration?.css_config?.borderRadiusTopRight}${widgetConfiguration?.css_config?.borderRadiusUnit}`,
      'border-bottom-left-radius': `${widgetConfiguration?.css_config?.borderRadiusBottomLeft}${widgetConfiguration?.css_config?.borderRadiusUnit}`,
      'border-bottom-right-radius': `${widgetConfiguration?.css_config?.borderRadiusBottomRight}${widgetConfiguration?.css_config?.borderRadiusUnit}`,
      'border-color': `${widgetConfiguration?.css_config?.borderColor}`,
      'border-width': `${widgetConfiguration?.css_config?.borderWidth}px`,
      'border-style': `${widgetConfiguration?.css_config?.borderWidth === undefined ? '' : 'solid'}`,
    };
  }

  getBaseCssInputBorder(widgetConfiguration: UiZoneWidgetAttributeConfig) {
    const inputBorderRadiusUnit = widgetConfiguration?.css_config?.inputBorderRadiusUnit ?? 'px';
    const inputBorderRadiusTopLeft =
      widgetConfiguration?.css_config?.inputBorderRadiusTopLeft ??
      widgetConfiguration?.widgetProperties?.inputFieldSettings?.css_config.borderRadiusTopLeft;
    const inputBorderRadiusTopRight =
      widgetConfiguration?.css_config?.inputBorderRadiusTopRight ??
      widgetConfiguration?.widgetProperties?.inputFieldSettings?.css_config.borderRadiusTopRight;
    const inputBorderRadiusBottomLeft =
      widgetConfiguration?.css_config?.inputBorderRadiusBottomLeft ??
      widgetConfiguration?.widgetProperties?.inputFieldSettings?.css_config.borderRadiusBottomLeft;
    const inputBorderRadiusBottomRight =
      widgetConfiguration?.css_config?.inputBorderRadiusBottomRight ??
      widgetConfiguration?.widgetProperties?.inputFieldSettings?.css_config.borderRadiusBottomRight;
    const inputBorderColor =
      widgetConfiguration?.css_config?.inputBorderColor ??
      widgetConfiguration?.widgetProperties?.inputFieldSettings?.css_config.borderColor;
    const inputBorderWidth =
      widgetConfiguration?.css_config?.inputBorderWidth ??
      widgetConfiguration?.widgetProperties?.inputFieldSettings?.css_config.borderWidth;

    return {
      'border-top-left-radius': `${inputBorderRadiusTopLeft}${inputBorderRadiusUnit}`,
      'border-top-right-radius': `${inputBorderRadiusTopRight}${inputBorderRadiusUnit}`,
      'border-bottom-left-radius': `${inputBorderRadiusBottomLeft}${inputBorderRadiusUnit}`,
      'border-bottom-right-radius': `${inputBorderRadiusBottomRight}${inputBorderRadiusUnit}`,
      'border-color': `${inputBorderColor}`,
      'border-width': `${inputBorderWidth}px`,
      'border-style': `${widgetConfiguration?.css_config?.inputBorderWidth === undefined ? '' : 'solid'}`,
    };
  }

  getBaseCssSelectedBorder(widgetConfiguration: UiZoneWidgetAttributeConfig) {
    return {
      'border-color': `${widgetConfiguration?.css_config?.selectedBorderColor}`,
      'border-width': `${widgetConfiguration?.css_config?.borderWidth}px`,
      'border-style': `${widgetConfiguration?.css_config?.borderWidth === undefined ? '' : 'solid'}`,
    };
  }

  getBaseCssVerticalAlignDisplayBlock(widgetConfiguration: UiZoneWidgetAttributeConfig) {
    if (widgetConfiguration?.css_config?.noAlignment) {
      return {};
    }
    return {
      display: `block`,
      'align-content': `${widgetConfiguration?.css_config?.verticalAlignment}`,
    };
  }

  getBaseCssVerticalAlign(widgetConfiguration: UiZoneWidgetAttributeConfig) {
    if (widgetConfiguration?.css_config?.noAlignment) {
      return {};
    }
    const justifyContent = widgetConfiguration?.css_config?.textAlignment as TextAlignToJustifyContent;
    return {
      display: `flex`,
      'align-items': `${widgetConfiguration?.css_config?.verticalAlignment}`,
      'justify-content': `${justifyContent}`,
    };
  }

  getBaseCssTextAlignToJustifyContent(widgetConfiguration: UiZoneWidgetAttributeConfig) {
    const justifyContent = widgetConfiguration?.css_config?.textAlignment as TextAlignToJustifyContent;
    return {
      'justify-content': `${justifyContent}`,
    };
  }

  getActivePageStateName(): string {
    return '';
  }

  getActiveConfigType() {
    return { isWorkflow: false };
  }

  getZoneByRoute(): Observable<{ zone: string; pageIndex: number }> {
    this.collectRouteParams();

    return combineLatest([this.newRoleSet, this.getWidgetsConfig()]).pipe(
      take(1),
      filter(([, config]) => {
        const entry = Object.entries(config.zones).find(([zone]) => {
          return formatNameAsUrl(zone) === this.zoneUrls.zoneUrl;
        });
        const zoneRoles = entry ? config.zones[entry[0]]?.roles : undefined;
        return entry !== undefined && zoneRoles !== undefined && Object.keys(zoneRoles).length > 0;
      }),
      map(([role, config]) => {
        this.setupRoleTheme(role.role);

        const entry = Object.entries(config.zones).find(([zone]) => {
          return formatNameAsUrl(zone) === this.zoneUrls.zoneUrl;
        });

        if (entry) {
          let pageIndex = null;
          if (this.zoneUrls.pageUrl !== undefined) {
            try {
              if (entry[1].roles[this.roleInfo.role] === undefined) {
                console.error(
                  `The persona has not been set before accessing page it seems... persona: ${JSON.stringify(
                    this.roleInfo,
                  )}, currentUrl: ${window.location.href}, this.zoneUrls.pageUrl: ${
                    this.zoneUrls.pageUrl
                  }, this.zoneUrls.zoneUrl: ${this.zoneUrls.zoneUrl}`,
                );
              }

              pageIndex = entry[1].roles[this.roleInfo.role][this.roleInfo.subscription][
                this.roleInfo.device
              ].pages.findIndex((page) => {
                return page.url === this.zoneUrls.pageUrl;
              });
            } catch (err) {
              console.error(
                `The persona has not been set before accessing page it seems... persona: ${JSON.stringify(
                  this.roleInfo,
                )}, currentUrl: ${window.location.href}, this.zoneUrls.pageUrl: ${
                  this.zoneUrls.pageUrl
                }, this.zoneUrls.zoneUrl: ${this.zoneUrls.zoneUrl}`,
              );
              Sentry.captureException(err);
            }
          } else {
            console.error(
              `We've ended up on a zone but no page url somehow, zone: ${this.zoneUrls.zoneUrl} default to zones pageIndex 0 to recover`,
            );
            pageIndex = 0;
          }
          return [
            {
              zone: entry[0],
              pageIndex: pageIndex,
            },
            config,
          ] as [{ zone: string; pageIndex: number }, UiSystemConfig];
        }
        throw new Error(`Cannot find zone and page index with ${JSON.stringify(this.zoneUrls)}`);
      }),
      // if exception got thrown while resolving page index filter the emission
      filter(([zone]) => {
        if (zone.pageIndex !== null) {
          return true;
        } else {
          console.warn("pageIndex has ended up null so we need to skip emitting as couldn't find page");
          return false;
        }
      }),
      filter(([zone, config]) => {
        const roles = config.zones[zone.zone]?.roles;
        return roles && Object.keys(roles).length > 0;
      }),
      map(([zone]) => zone),
    );
  }

  getInputFieldCssStyles(widgetConfig: BaseWidgetProperties) {
    const iconStyle = this.getSearchInputCssStyles(widgetConfig, [
      BaseCssProperties.ICON_SIZE,
      BaseCssProperties.COLOR,
    ]);

    const baseStyle = this.getSearchInputCssStyles(widgetConfig, [
      BaseCssProperties.BORDER,
      BaseCssProperties.BACKGROUND_COLOR,
    ]);
    const activeStyle = this.getSearchInputCssStyles(widgetConfig, [
      BaseCssProperties.HEIGHT_WIDTH,
      BaseCssProperties.COLOR,
    ]);

    const inactiveStyle = this.getSearchInputCssStyles(widgetConfig, [
      BaseCssProperties.DISABLED_BORDER_COLOR,
      BaseCssProperties.DISABLE_BACKGROUND_COLOR,
    ]);

    return {
      iconStyle,
      baseStyle,
      activeStyle,
      inactiveStyle,
    };
  }

  getSearchInputCssStyles(
    widgetProperties: BaseWidgetProperties,
    propertyName: string[],
  ): baseCssConfigStyle {
    let cssProperty = {};
    propertyName.forEach((cssPropertyName) => {
      switch (cssPropertyName) {
        case BaseCssProperties.BORDER:
          cssProperty = {
            ...cssProperty,
            ...this.getSearchInputBorderCss(widgetProperties),
          };
          break;

        case BaseCssProperties.BACKGROUND_COLOR:
          cssProperty = {
            ...cssProperty,
            ...this.getSearchInputBackgroundColorCss(widgetProperties),
          };
          break;

        case BaseCssProperties.DISABLE_BACKGROUND_COLOR:
          cssProperty = {
            ...cssProperty,
            ...this.getSearchInputBackgroundColorCss(widgetProperties, 'disabled'),
          };
          break;

        case BaseCssProperties.DISABLED_BORDER_COLOR:
          cssProperty = {
            ...cssProperty,
            ...this.getSearchInputBorderCss(widgetProperties, 'disabled'),
          };
          break;

        case BaseCssProperties.ICON_SIZE:
          cssProperty = {
            ...cssProperty,
            ...this.getSearchInputIconSize(widgetProperties),
          };
          break;

        case BaseCssProperties.HEIGHT_WIDTH:
          cssProperty = {
            ...cssProperty,
            ...this.getSearchInputIconSize(widgetProperties, 'inputHeightWidth'),
          };
          break;

        case BaseCssProperties.COLOR:
          cssProperty = {
            ...cssProperty,
            ...this.getSearchInputTextColor(widgetProperties),
          };
          break;
        default:
          break;
      }
    });

    return cssProperty;
  }
  getSearchInputTextColor(widgetProperties: BaseWidgetProperties): baseCssConfigStyle {
    return {
      color: `${widgetProperties?.inputFieldSettings?.css_config?.inputTextColor}`,
    };
  }
  getSearchInputBorderCss(widgetProperties: BaseWidgetProperties, type?: string): baseCssConfigStyle {
    const colorType = type === 'disabled' ? 'inactiveBorderColor' : 'borderColor';
    return {
      'border-top-left-radius': `${widgetProperties?.inputFieldSettings?.css_config?.borderRadiusTopLeft}${widgetProperties?.inputFieldSettings?.css_config?.borderStyleUnit}`,
      'border-top-right-radius': `${widgetProperties?.inputFieldSettings?.css_config?.borderRadiusTopRight}${widgetProperties?.inputFieldSettings?.css_config?.borderStyleUnit}`,
      'border-bottom-left-radius': `${widgetProperties?.inputFieldSettings?.css_config?.borderRadiusBottomLeft}${widgetProperties?.inputFieldSettings?.css_config?.borderStyleUnit}`,
      'border-bottom-right-radius': `${widgetProperties?.inputFieldSettings?.css_config?.borderRadiusBottomRight}${widgetProperties?.inputFieldSettings?.css_config?.borderStyleUnit}`,
      'border-color': `${widgetProperties?.inputFieldSettings?.css_config?.[colorType]}`,
      'border-width': `${widgetProperties?.inputFieldSettings?.css_config?.borderWidth}px`,
      'border-style': `${
        widgetProperties?.inputFieldSettings?.css_config?.borderWidth === undefined ? '' : 'solid'
      }`,
    };
  }
  getSearchInputBackgroundColorCss(
    widgetProperties: BaseWidgetProperties,
    type?: string,
  ): baseCssConfigStyle {
    const colorType = type === 'disabled' ? 'inactiveBackgroundColor' : 'backgroundColor';
    return {
      'background-color': `${widgetProperties?.inputFieldSettings?.css_config?.[colorType]}`,
    };
  }
  getSearchInputIconSize(widgetProperties: BaseWidgetProperties, type?: string): baseCssConfigStyle {
    if (type === 'inputHeightWidth') {
      return {
        height: `${widgetProperties?.inputFieldSettings?.css_config?.inputHeight}px`,
        width: `${widgetProperties?.inputFieldSettings?.css_config?.inputWidth}%`,
      };
    } else {
      return {
        height: `${widgetProperties?.inputFieldSettings?.css_config?.iconSize}${widgetProperties?.inputFieldSettings?.css_config?.iconSizeUnit}`,
        width: `${widgetProperties?.inputFieldSettings?.css_config?.iconSize}${widgetProperties?.inputFieldSettings?.css_config?.iconSizeUnit}`,
      };
    }
  }

  public getCardCssStyles(
    widgetConfiguration: UiZoneWidgetAttributeConfig,
    propertyName: string[],
  ): baseCssConfigStyle {
    let cssProperty = {};
    propertyName.forEach((cssPropertyName) => {
      switch (cssPropertyName) {
        case BaseCssProperties.MARGIN:
          cssProperty = {
            ...cssProperty,
            ...this.getBaseCssMargin(widgetConfiguration),
          };
          break;

        case BaseCssProperties.PADDING:
          cssProperty = {
            ...cssProperty,
            ...this.getBaseCssPadding(widgetConfiguration),
          };
          break;

        case BaseCssProperties.COLOR:
          cssProperty = {
            ...cssProperty,
            ...this.getBaseCssColor(widgetConfiguration),
          };
          break;

        case BaseCssProperties.BORDER:
          cssProperty = {
            ...cssProperty,
            ...this.getBaseCssBorder(widgetConfiguration),
          };
          break;
        case BaseCssProperties.BOX_SHADOW:
          cssProperty = {
            ...cssProperty,
            ...this.getBoxShadow(widgetConfiguration),
          };
          break;
        case BaseCssProperties.HEIGHT_WIDTH:
          cssProperty = {
            ...cssProperty,
            ...this.getHeightWidth(widgetConfiguration),
          };
          break;
        case BaseCssProperties.BACKGROUND_COLOR:
          cssProperty = {
            ...cssProperty,
            ...this.getBackground(widgetConfiguration),
          };
          break;
        case BaseCssProperties.OBJECT_FIT:
          cssProperty = {
            ...cssProperty,
            ...this.getBaseCssObjectFit(widgetConfiguration),
          };
          break;
        default:
          break;
      }
    });
    return cssProperty;
  }

  public getHeightWidth(widgetConfiguration: UiZoneWidgetAttributeConfig) {
    return {
      height: `${widgetConfiguration?.css_config?.height}${widgetConfiguration?.css_config?.heightDimensionUnit}`,
      width: `${widgetConfiguration?.css_config?.width}${widgetConfiguration?.css_config?.widthDimensionUnit}`,
    };
  }
  public getBackground(widgetConfiguration: UiZoneWidgetAttributeConfig) {
    if (widgetConfiguration?.widgetProperties?.['cardStylingOptions']?.imagePath) {
      return {
        'background-image': `${widgetConfiguration.widgetProperties['cardStylingOptions'].imagePath}`,
      };
    } else if (widgetConfiguration?.widgetProperties?.['cardStylingOptions']?.useGradient) {
      return {
        background: `${widgetConfiguration?.css_config?.backgroundGradient}`,
      };
    } else {
      return {
        background: `${widgetConfiguration?.css_config?.backGroundColor}`,
      };
    }
  }

  uploadFile<Type>(
    formData: FormData,
    uploadParams?: {
      private?: boolean;
    },
  ) {
    return this.getApi('file_service').pipe(
      switchMap((baseUrl) => {
        return this.http.post<Type>(baseUrl + 'file/file-upload', formData, {
          reportProgress: true,
          observe: 'events',
          params: uploadParams,
        });
      }),
    );
  }
  getStates(state: string): Observable<State[]> {
    return this.stateService.getState(state);
  }

  setDevice() {
    const deviceInfo = this.deviceDetectorService.getDeviceInfo();
    this.store.dispatch(new UpdateDevice(deviceInfo.deviceType as SupportedDevice));
  }

  private async loadCssVariables(): Promise<void> {
    const linkElement = document.getElementById('globalTheme') as HTMLLinkElement;

    if (linkElement) {
      try {
        // Fetch the CSS file content
        const response = await fetch(linkElement.href);
        const cssText = await response.text();
        this.globalCssString.set(cssText);
        this.extractRootVariables(cssText);
      } catch (error) {
        console.error('Error loading CSS variables:', error);
      }
    }
  }

  private extractRootVariables(cssContent: string): void {
    const rootMatches = cssContent.matchAll(/:root\s*{([^}]*)}/gs);
    const result: Record<string, string> = {};
    for (const match of rootMatches) {
      const rootContent = match[1];
      const variables = Array.from(rootContent.matchAll(/(--[\w-]+)\s*:\s*([^;]+);/g));
      variables.forEach(([_, name, value]) => {
        result[name.trim()] = value.trim(); // Later definitions overwrite earlier ones
      });
    }
    this.globalCssVariables.set(result);
  }

  public getCssVars(): Record<string, string> {
    return this.globalCssVariables();
  }
}
