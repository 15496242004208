import { PageContextStateModel } from '@app/finxone-web-frontend/app/lib/state/page-context.state';

export class SetPageContext {
  static readonly type = '[PageContextAction] Set Page Context';
  constructor(public payload: PageContextStateModel) {}
}

export class CloseModalPopupAction {
  static readonly type = '[PageContextAction] Close Modal Popup';
}
