import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

import {
  iconSetMaterial,
  iconSetMaterialBold,
  iconSetMisc,
  iconSetPayabl,
  iconSetPrimeNg,
  iconSetRevenir,
  IconType,
} from '@finxone-platform/shared/sys-config-types';
import { IconCacheService } from '@finxone-platform/shared/services';

export const registerCustomIcons = function (
  matIconRegistry: MatIconRegistry,
  sanitizer: DomSanitizer,
  iconCacheService?: IconCacheService,
) {
  // Define a helper function to add SVG icons
  function addSvgIcon(iconName: string, filePath: string) {
    matIconRegistry.addSvgIcon(iconName, sanitizer.bypassSecurityTrustResourceUrl(filePath));
    if (iconCacheService) {
      // fetch the <svg>
      fetch(filePath)
        .then((response) => response.text())
        .then((svg) => {
          // cache the <svg>
          if (!iconCacheService.hasIcon(iconName)) {
            iconCacheService.setIcon(iconName, svg);
          } else {
            console.log(`addSvgIcon ${iconName} already exists!`);
          }
        });
    }
  }

  const allIcons: IconType[] = [];
  allIcons.push(...iconSetPrimeNg);
  allIcons.push(...iconSetRevenir);
  allIcons.push(...iconSetMaterialBold);
  allIcons.push(...iconSetMaterial);
  allIcons.push(...iconSetMisc);
  allIcons.push(...iconSetPayabl);
  // add more sets here

  // Loop through the icons object and add each icon
  allIcons.forEach((icon) => {
    addSvgIcon(icon.name, icon.url);
  });
};
