import { Injector } from '@angular/core';
import { Router } from '@angular/router';
import { AlertHandlerService } from '@finxone-platform/shared/services';
import { APP_ZONES, UiZoneWidgetAttributeConfig } from '@finxone-platform/shared/sys-config-types';
import { Store } from '@ngxs/store';
import { take, tap } from 'rxjs';
import { CancelCardAction } from '../../../actions/card-management.action';
import { GetCards } from '../../../actions/card.actions';
import { CardState } from '../../../state/card.state';
import { formatNameAsUrl } from '../../zone-url.utils';

export function onCancelCard(store: Store, alertHandlerService: AlertHandlerService, router: Router): void {
  const cardId = store.selectSnapshot(CardState.getCurrentCardId);
  if (!cardId) {
    alertHandlerService.showAlertFn('error', 'Card information is missing.');
    return;
  }
  store
    .dispatch(new CancelCardAction(cardId))
    .pipe(
      take(1),
      tap({
        next: () => {
          router.navigateByUrl(`/zones/${formatNameAsUrl(APP_ZONES.CARD_MANAGEMENT_ZONE)}/card-cancelled`);
        },
        error: (error) => {},
      }),
    )
    .subscribe();
}

export function refreshCardsAndRedirect(injector: Injector, attri: UiZoneWidgetAttributeConfig) {
  const store = injector.get(Store);
  const router = injector.get(Router);
  store.dispatch(new GetCards());
  router.navigateByUrl(
    `/zones/${formatNameAsUrl(attri.widgetProperties['zoneToNavigate'])}/${
      attri.widgetProperties['urlToNavigate']
    }`,
  );
}
